import React, { useState, useEffect, useRef, useReducer } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY, getHyphenDDMMYYYYHHMM } from '../common/utils';
import ConsignmentTrucksLegs from "./containertruckslegs";
import AcceptBtn from "./acceptbtncomponent";
import RejectBtn from "./rejectbtncomponent";
import CountryFlagComponent from "./countryFlag";
import LeoDateComponent from "./leodatecomponent";
import Select from "react-select";
import Mapcard2 from "./drawmap";
import UpdateIBBufferapproval from "../layouts/updateibbufferapprovalbtn"
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import { fetchGrid } from "../fetchGridStructure"
import redirectURL from "../redirectURL";
var initialState = {
    detailCellRendererParams: {},
};

var reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DETAIL_RENDERER_PARAMS':
            //console.log('2020')
            return {
                ...state,
                detailCellRendererParams: action.payload,
            };
        // case 'SET_DETAIL_RENDERER_PARAMS_FOR_STOPPAGES':
        //  //console.log('6767')
        //  return {
        //      ...state,
        //      detailCellRendererParams: action.payload,
        //  };
        default:
            return state;
    }
};
const IBBuffer = (props) => {
    const gridApi = useRef(null);
    const gridColumnApi = useRef(null);
    var [state, dispatch] = useReducer(reducer, initialState);
    const [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    // var [gridApi, setGridApi] = useState(null);
    // var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [checkfortruck, setcheckfortruck] = useState(0)
    var [selected_row, setselected_row] = useState({})

    const [showAddForm, setshowAddForm] = useState('')
    var selectconfirmn = [
        { label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }
    ]
    var [loadshow, setloadshow] = useState('show-n')
    var [loadData , setLoadData] = useState(false)
    var [overly, setoverly] = useState('show-n')
    var [trucksList, settrucksList] = useState([])
    var [value, setvalue] = useState('')
    var [value1, setvalue1] = useState(0)
    const [manualUploadDetails, setmanualUploadDetails] = useState({
        remarks_by_ib: '',
        approvalstatus: '',
        wrno: ''
    })
    var [workordernoList, setworkOrderList] = useState([])
    var [containerLegsData, setContainerLegsData] = useState([])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [parentrow, setparentrow] = useState({})
    var [stateForMap, setStateForMap] = useState({
        sliderRouteTranslate: '',
        showDiv: 'show-n',
        mapinfo: {
            coords: [
                // { lat: 8.76735, lng: 78.13425 },
                // { lat: 18.76735, lng: 88.13425 },
            ],
        },
        mapdata: [],
        dealer: '',
        consignment_code: '',
        truck_no: '',
        container_no: '',
        maptruckno: '',
        routeTruck: '',
        sidebarSubHeader: '',
        rownode: '',
        consigner_coordinates: '',
        consignee_coordinates: '',
        map_type_of_tracking: '',
        baseRouteCoords: '',
        mapHeight: "94vh",
    })
    const defaulColumnDefs = [
        {
            headerName: "Update Approval Status",
            field: "update_approval_status",
            width: 200,
            pinned: 'left',
            cellRenderer: 'UpdateIBBufferapproval'
        },
        {
            headerName: "",
            field: "containerLegs",
            width: 60,
            pinned: 'left',
            cellRenderer: 'ConsignmentTrucksLegs'
        },
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            cellEditor: DateEditor,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    } else {
                        return "";
                    }
                } catch (e) {
                    // Handle the exception if needed
                }
            },
            comparator: dateComparator1,

        },
        // {
        //     headerName: "Tentative Vessel ETD",
        //     field: "tentative_vessel_etd",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_vessel_etd != "" && params.data.tentative_vessel_etd != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
        //             }
        //             else {
        //                 return "";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
            comparator: dateComparator1,
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 130,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Pendency",
        //     field: "remaining_containers",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        // },
        {
            headerName: "POD",
            field: "pod",
            width: 200,
            filter: true,
            resizable: true,
        },

        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Booking No",
            field: "booking_no",
            width: 160,
            filter: true,
            resizable: true,
        },

        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: 'LEO Date',
            field: "leo_date",
            resizable: true,
            width: 180,
            filter: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.leo_date != "" && params.data.leo_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.leo_date);
                    } else {
                        return "";
                    }
                } catch (e) {
                    // Handle the exception if needed
                }
            },
            comparator: dateComparator1,
        },
    ]
    const [summaryViewcols, setsummaryViewcols] = useState(defaulColumnDefs)
    useEffect(() => {
        if (gridApi.current) {
            getUserGridState();
        }
        loadDateTimeScript();
    }, [gridApi]);

    useEffect(() => {
        // var records = props.data.wr_data
        // if (records != '' && records != undefined) {
        //     records = records.filter(e => e.buffer_approved != 1)
        // }
        // setrowData(records)
        // setContainerLegsData(props.data.container_data)
        // setoriginalData(records)
        if (!loadData) {
            getIbBuffer()
        }        
    }, [props.data])
    const getIbBuffer = async () => {
        setloadshow("show-m");
        setLoadData(true)
        await redirectURL.post('/consignments/getIbBufferData').then(async (resp) => { // Optimized api for Plant Data
            var records = resp.data
            if (records != '' && records != undefined) {
                records = records.filter(e => e.buffer_approved != 1)
            }
            setrowData(records)
            setoriginalData(records)
            setloadshow("show-n");
             setTimeout(() => {
                setLoadData(false);
            }, 1000);
        }).catch(e => {
            console.log("Error :", e)
            setloadshow("show-n");
             setTimeout(() => {
                setLoadData(false);
            }, 1000);
        });
    }
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const updateBtn = () => {
        setshowAddForm("slide30")
        setoverly("show-m")
    }
    const onSelectWON = (e) => {
        //console.log(e)
        setmanualUploadDetails(prev => ({
            ...prev,
            approvalstatus: e
        }))
    }

    var onCloseRouteDiv = () => {
        setStateForMap(prevState => ({
            showDiv: 'show-n',
            sliderRouteTranslate: "",
        }))
        setoverly('show-n')
    }
    var onClickuploadLeoDate = async (e) => {
        var params = {
            // container_no:e.container_no ,
            work_order_no: e.data.work_order_no,
            // truck_no:e.truck_no,
        }
        var check = 0
        if (e.data.leo_date != undefined && e.data.leo_date != null && e.data.leo_date != "") {
            params.leo_date = e.data.leo_date
            check = 1
        }
        if (check != 0) {
            //console.log(params, 'params')
            redirectURL.post('/consignments/updateContainerleodate', params).then((resp) => {
                //console.log(resp.data)
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("LEO Date has been successfully updated!")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                } else {
                    setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
                }
            })
        } else {
            setshow(true)
            setbasicTitle("Please Provide LEO Date")
            setbasicType("danger")
            setloadshow('show-n')
            setoverly('show-n')
        }
    }
    const onShowShipmentLegRouteMap = async (e) => {
        var params = {
            work_order_no: e.work_order_no,
            truck_no: e.truck_no,
            container_no: e.container_no
        }
       
        setloadshow('show-m');
        setoverly('show-m');

        try {
            const resp = await redirectURL.post('/consignments/getmapdata', params);
            //console.log(resp.data);
            var gps_route = resp.data.gps_route.coords;
            if (!Array.isArray(gps_route)) {
                gps_route = gps_route.replace(/NaN/g, "0");
                gps_route = JSON.parse(gps_route);
            }
            if (!gps_route || gps_route.length === 0) {
                setshow(true);
                setbasicTitle('No GPS Data Available');
                setbasicType('danger');
                setloadshow('show-n');
                setoverly('show-n');

            } else {
                var allCoordinates = [];
                var allldbcoords = [];
                var gps_route =gps_route;
                var ldb_data = resp.data.ldb_route
                var ldb_route = ldb_data.ldb_route;

                //console.log('gps_route', gps_route);
                //console.log('ldb_route', ldb_route);
                if (ldb_route != '' && ldb_route != undefined) {
                    if (ldb_route.length > 0) {
                        ldb_route = JSON.parse(ldb_route)
                        ldb_route.forEach((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;

                            allldbcoords.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('ldb_route is not an array or is empty.');
                    }
                }
                //console.log(gps_route, 'gps_routegps_route')
                if (gps_route != '' && gps_route != undefined) {
                    if (gps_route.length > 0) {
                        //console.log(typeof gps_route, 'gps_routegps_route')
                        gps_route.map((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;
                            allCoordinates.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('gps_route is not an array or is empty.');
                    }
                }

                var truck_no;
                if (gps_route.length > 0) {
                    truck_no = gps_route[0].truck_no;
                }
                //console.log(allCoordinates, 'allCoordinates');
                setoverly('show-m')
                setStateForMap((prev) => ({
                    ...prev,
                    sliderRouteTranslate: "slider-translate-75p",
                    showDiv: 'show-m',
                    container_no: truck_no,
                    mapinfo: {
                        ...state.mapinfo,
                        coords: allCoordinates,
                    },
                    mapdata:resp.data.gps_route

                }));
                setloadshow('show-n');
            }
        } catch (error) {
            console.error(error);
        }
    }
    var onClickacceptbtn = (e, k) => {
        setshowAddForm("slide30")
        setoverly("show-m")
        setvalue(e)
        setvalue1(k)

    }
    var onClickibapproval = (e) => {
        setselected_row(e.data)
        setshowAddForm("slide30")
        setoverly("show-m")
    }

    var onClickShowShipmentLegs = async (rownode) => {
        var commentHeaders = [
            {
                headerName: "",
                field: "",
                resizable: true,
                width: 60,
                filter: true,
                // cellRendererFramework: LegRouteActiveTruckMap,
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <button onClick={() => onShowShipmentLegRouteMap(params.data)} className="custom-btn label label-success">
                                <i className="icofont icofont-map-pins f20"></i>
                            </button>
                        </div>
                    );
                },
            },
            {
                headerName: 'Requirement Id',
                field: "requirement_id",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Work Order No',
                field: "work_order_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Truck No',
                field: "truck_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Container No',
                field: "container_no",
                resizable: true,
                width: 180,
                filter: true

            },
            // {
            //     headerName: 'Pickup Date',
            //     field: "pick_up_date",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
            //                 return getHyphenDDMMYYYY(params.data.pick_up_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (e) {
            //             // Handle the exception if needed
            //         }
            //     },

            // },
            // {
            //     headerName: 'Shipping Seal',
            //     field: "shipping_seal",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            // },
            {
                headerName: 'Invoice No',
                field: "invoice_no",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,

            },
            {
                headerName: 'Invoice Date',
                field: "invoice_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.invoice_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'RFID',
                field: "rfid",
                resizable: true,
                width: 150,
                filter: true,
                editable: false,
            },
            {
                headerName: 'Gate Out Time',
                field: "gate_out_time",
                resizable: true,
                width: 150,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_out_time != "" && params.data.gate_out_time != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.gate_out_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'Reporting Time At Parking Plaza',
                field: "reporting_at_parking",
                resizable: true,
                width: 230,
                filter: true,
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.reporting_at_parking != "" && params.data.reporting_at_parking != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.reporting_at_parking);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: "Approval Status",
                field: "Approval Status",
                resizable: true,
                width: 150,
                filter: true,
                cellRendererFramework: (params) => {
                    if (params.data.buffer_accepted != 1 && params.data.buffer_rejected != 1) {
                        return (
                            <>
                            </>
                        );
                    }
                    else {
                        if (params.data.buffer_rejected == 1) {
                            return (
                                <div>
                                    Rejected
                                </div>
                            );
                        }
                        else if (params.data.buffer_accepted == 1) {
                            return (
                                <div>
                                    Accepted
                                </div>
                            );
                        }
                    }
                },
            },
            {
                headerName: "Remarks By IB",
                field: "buffer_remarks_by_ib",
                resizable: true,
                width: 160,
                filter: true,

            }
        ]
        var setdata = {
            detailGridOptions: {
                columnDefs: commentHeaders,
                overlayNoRowsTemplate: 'No rows to show',

            },
            getDetailRowData: async function (param) {
                param.successCallback([]);
                var row = param.data
                var parameter = {
                    work_order_no: row.work_order_no
                }
                await redirectURL.post("/consignments/truckslegsdata", parameter).then(async (response) => {
                    console.log(response.data, 'response')
                    var records = response.data
                    param.successCallback(records);
                }).catch(function (error) {
                    console.log(error);
                })
                // var records = containerLegsData.filter(e => e.work_order_no == row.work_order_no)
                // param.successCallback(records);
            },
            masterDetail: true
        }
        dispatch({ type: 'SET_DETAIL_RENDERER_PARAMS', payload: setdata });
        setTimeout(() => {
            if (rownode.column.colDef.field == 'containerLegs') {
                rownode.node.setExpanded(!rownode.node.expanded);

            }
            else {

                rownode.node.setExpanded(false);
            }
        }, 0)
    }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')

    }
    const onsubmitdata = (f) => {
        f.preventDefault()
        var k = 0
        if (manualUploadDetails.approvalstatus.value == 'accepted') {
            k = 1
        }
        else {
            k = 2
        }
        if (manualUploadDetails.approvalstatus != '' && manualUploadDetails.approvalstatus != undefined && manualUploadDetails.remarks_by_ib != '' && manualUploadDetails.remarks_by_ib != undefined) {
            if (k == '1' || k == 1) {
                var params = {
                    work_order_no: selected_row.work_order_no,
                    buffer_remarks_by_ib: manualUploadDetails.remarks_by_ib,
                    buffer_approved_by: localStorage.getItem('email'),
                    screenname: "ib_buffer",
                    actionType: "Approved Buffer Request",
                    userId: localStorage.getItem('userid'),
                    email: localStorage.getItem("email"),
                    username: localStorage.getItem("user_name"),
                    role: localStorage.getItem("role"),
                }
                redirectURL.post('/consignments/insertbuffer', params).then((resp) => {
                    if (resp.data.status == 'success') {
                        setshow(true)
                        setbasicTitle("Buffer Request Approved Successfully")
                        setbasicType("success")
                        setloadshow('show-n')
                        setoverly('show-n')
                        setshowAddForm('')
                        props.onRequest()
                        props.onReloadData(true);

                    }
                })
            }
            else if (k == 2 || k == '2') {
                var params = {
                    work_order_no: selected_row.work_order_no,
                    screenname: "ib_buffer",
                    actionType: "Rejected Buffer Request",
                    userId: localStorage.getItem('userid'),
                    email: localStorage.getItem("email"),
                    username: localStorage.getItem("user_name"),
                    role: localStorage.getItem("role"),
                }
                redirectURL.post('/consignments/rejectbuffer', params).then((resp) => {
                    if (resp.data.status == 'success') {
                        props.onRequest()
                        setshow(true)
                        setbasicTitle("Buffer Request Rejected")
                        setbasicType("danger")
                        setloadshow('show-n')
                        setoverly('show-n')
                        setshowAddForm('')

                    }
                })
            }
        }
        else{
            setshow(true)
                        setbasicTitle("Please Enter All Fields")
                        setbasicType("danger")
                        setloadshow('show-n')
                        setoverly('show-n')
        }
    }

    const onGridReady = params => {
        gridApi.current = params;
        gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: '/ibbuffer' , userId:localStorage.getItem("userid") })
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols = fetchGrid(summaryViewcols, grid[0].gridcolumns)
                //console.log("oCols", oCols)
            })
    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols = fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            //console.log(e);
        }
    };
    const onGridState = () => {
        ////console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */

        window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


    }
    var onClickSaveGridState = () => {
        try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = '/ibbuffer'
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: '/ibbuffer',
                screentitle: screenpage
            }
            //console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    //console.log("Error ", e)
                })
            //console.log('hit')
        }
        catch (e) {
            //console.log(e)
        }
    }
    const resetState = () => {
        try {
            // Reset columns to their initial state (order, visibility, width)
            setsummaryViewcols(defaulColumnDefs)
            const initialColumnState = defaulColumnDefs.map(col => ({
                colId: col.field,
                hide: col.hide || false,
                ...col
            }));
            // Apply the column state to reset order and visibility
            gridColumnApi.current.applyColumnState({
                state: initialColumnState,
                applyOrder: true  // This ensures that the column order is also reset
            });
            gridApi.current.api.refreshCells();
            // After the reset, you may want to fetch the user grid state from the backend
            const reqparams = {
                userId: localStorage.getItem("userid"),
                screenurl: "/ibbuffer",
                screentitle: '/ibbuffer',  // Adjust to your actual screen title
            };
            redirectURL.post("/consignments/removeUserGridState", reqparams)
                .then((response) => {
                    setshow(true);
                    setbasicTitle("Successfully reset default grid layout");
                    setbasicType("success");
                    // Optionally, reload grid state from the backend (if needed)
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000);
                })
                .catch((error) => {
                    setshow(true);
                    setbasicTitle("Error resetting grid layout");
                    setbasicType("error");
                    console.error('Error resetting grid state:', error);
                });
        } catch (error) {
            console.error('Unexpected error in resetState:', error);
        }
    };
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const onSelectConfirm = (e) => {
        if (e.value == 'yes') {
            setcheckfortruck(1)
        }
        else {
            setshowAddForm('')
            setoverly('show-n')
        }
    }
    const handleTrucksList = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            trucks: e
        }))
    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    const detailedviewcols = []
    var columns1 = [...summaryViewcols]
    const columnwithDefs = columns1
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div style={{ display: 'flex', justifyContent: 'flex-end', position: "relative", top: "-35px" }}>
                <button
                    type="button"
                    title="Save Grid Layout"
                    name="savegrid"
                    className="btn btn-danger action_btn"
                    style={{ height: "32px", marginTop: "30px", paddingBottom: "30px"}}

                    onClick={onClickSaveGridState}>
                    <i className="f12 icofont icofont-save"></i>
                </button>
                <button
                    style={{ height: "32px", marginTop: "30px", paddingBottom: "30px", marginLeft: "4px" , marginRight: "10px"  }}
                    title="Reset Default Layout"
                    className="float-right btn white btn-info" onClick={resetState}>
                    <i className="icofont icofont-refresh"></i>
                </button>
            </div>
            {props.check == 7 ?
                <div className="" style={{ marginLeft: '10px', marginRight: "10px", marginbottom: '10px', marginTop: '0px', position: "relative", top: "-33px" }}>
                    <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                        <AgGridReact
                            ref={gridApi}
                            columnDefs={columnwithDefs}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                                // rowHeight:80,
                            }}
                            // paddingTop={10}
                            // rowHeight={70}
                            // headerHeight = {70}
                            //  headerHeight={50}
                            rowData={rowData}
                            suppressRowClickSelection={true}
                            detailCellRendererParams={state.detailCellRendererParams}
                            frameworkComponents={{ UpdateIBBufferapproval: UpdateIBBufferapproval, ConsignmentTrucksLegs: ConsignmentTrucksLegs, CountryFlagComponent: CountryFlagComponent, RejectBtn: RejectBtn, AcceptBtn: AcceptBtn }}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            enableRangeSelection={true}
                            paginationPageSize={50}
                            onGridReady={onGridReady}
                            onGridState={onGridState}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            sideBar={{
                                toolPanels: [
                                    {
                                        id: "columns",
                                        labelDefault: "Columns",
                                        labelKey: "columns",
                                        iconKey: "columns",
                                        toolPanel: "agColumnsToolPanel"
                                    },
                                    {
                                        id: "filters",
                                        labelDefault: "Filters",
                                        labelKey: "filters",
                                        iconKey: "filter",
                                        toolPanel: "agFiltersToolPanel"
                                    }
                                ]
                            }}
                            statusBar={{
                                statusPanels: [
                                    {
                                        statusPanel: "agTotalAndFilteredRowCountComponent",
                                        align: "left"
                                    },
                                    {
                                        statusPanel: "agTotalRowCountComponent",
                                        align: "center"
                                    },
                                    { statusPanel: "agFilteredRowCountComponent" },
                                    { statusPanel: "agSelectedRowCountComponent" },
                                    { statusPanel: "agAggregationComponent" }
                                ]
                            }}
                            gridOptions={{
                                icons: {
                                    // You can customize other icons as well
                                    next: `<img src="${customNextIcon}" alt="Next" />`,
                                    previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                    first: `<img src="${customFirstIcon}" alt="First" />`,
                                    last: `<img src="${customLastIcon}" alt="Last" />`,
                                },
                            }}
                            // ref={gridRef}
                            context={{ onClickShowShipmentLegs, onClickibapproval }}
                        />

                    </div>
                </div> : ''}
            <div className={"sliderBlock2 " + showAddForm} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Details</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Approval Status<span className="err-txt">*</span></label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.approvalstatus}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e, "wrno")}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={[{ label: 'Accept', value: 'accepted' }, { label: 'Reject', value: 'rejected' }]}
                                        />
                                    </div>

                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Remarks By IB<span className="err-txt">*</span></label>
                                        <input
                                            type="text"
                                            name="remarks_by_ib"
                                            id="remarks_by_ib"
                                            value={manualUploadDetails.remarks_by_ib}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, remarks_by_ib: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            {stateForMap.sliderRouteTranslate != '' ?
                <div className={"sliderBlock2 " + stateForMap.sliderRouteTranslate} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            {(stateForMap.container_no)}
                            <span className="float-right closebtn" style={{ marginRight: "100px" }} onClick={onCloseRouteDiv} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            {/* {//console.log("this.state.mapinfo ", this.state.mapinfo)} */}
                            <Mapcard2
                                mapinfo={stateForMap.mapinfo}
                                mapHeight={stateForMap.mapHeight}
                                mapData={stateForMap.mapdata}
                                mapFor={'intransit'}

                            />
                        </div>
                    </div>

                </div>
                : ""
            }
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>



    )
}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });
    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
    });
}
function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        //console.log(date1Number, date2Number, "comparator")
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function loadDateTimeScript2() {
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        //console.log(this.eInput)
        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}
function DateEditor() { }
DateEditor.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        showTime: false,
    });
};
DateEditor.prototype.getGui = function () {
    return this.eInput;
};
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};
DateEditor.prototype.destroy = function () {
};
DateEditor.prototype.isPopup = function () {
    return false;
};
function removeDuplicates(arr, prop) {
    const seen = new Set();
    return arr.filter(obj => {
        const propValue = obj[prop];
        const propString = typeof propValue === 'object' ? JSON.stringify(propValue) : propValue;
        if (seen.has(propString)) {
            return false;
        }
        seen.add(propString);
        return true;
    });
}
export default IBBuffer