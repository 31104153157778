import React, { useState, useEffect, useRef, useReducer } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY,getHyphenYYYYMMDDHHMM } from '../common/utils';
import ConsignmentTrucksLegs from "./containertruckslegs";
import CountryFlagComponent from "./countryFlag";
import Select from "react-select";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import redirectURL from "../redirectURL";
var initialState = {
    detailCellRendererParams: {},
};

var reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DETAIL_RENDERER_PARAMS':
            console.log('2020')
            return {
                ...state,
                detailCellRendererParams: action.payload,
            };
        // case 'SET_DETAIL_RENDERER_PARAMS_FOR_STOPPAGES':
        //  console.log('6767')
        //  return {
        //      ...state,
        //      detailCellRendererParams: action.payload,
        //  };
        default:
            return state;
    }
};
const ClosedComponentForPlant = (props) => {
    var gridApi = useRef(null)    
    var gridColumnApi = useRef(null)
    var [state, dispatch] = useReducer(reducer, initialState);
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    // var [gridApi, setGridApi] = useState(null);
    // var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    const [showAddForm, setshowAddForm] = useState('')
    var [checkfortruck, setcheckfortruck] = useState(0)
    var selectconfirmn = [
        { label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }
    ]
    var [loadshow, setloadshow] = useState('show-n')
    var [loadData , setLoadData] = useState(false)
    var [overly, setoverly] = useState('show-n')
    var [trucksList, settrucksList] = useState([])
    const [manualUploadDetails, setmanualUploadDetails] = useState({
        trucks: '',
        wrno: ''
    })
    var [workordernoList, setworkOrderList] = useState([])
    var [containerLegsData, setContainerLegsData] = useState([])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [parentrow, setparentrow] = useState({})
    var [stateForMap, setStateForMap] = useState({
        sliderRouteTranslate: '',
        showDiv: 'show-n',
        mapinfo: {
            coords: [
                // { lat: 8.76735, lng: 78.13425 },
                // { lat: 18.76735, lng: 88.13425 },
            ],
        },
        dealer: '',
        consignment_code: '',
        truck_no: '',
        container_no: '',
        maptruckno: '',
        routeTruck: '',
        sidebarSubHeader: '',
        rownode: '',
        consigner_coordinates: '',
        consignee_coordinates: '',
        map_type_of_tracking: '',
        baseRouteCoords: '',
        mapHeight: "94vh",
    })
    useEffect(() => {
        if (gridRef.current) {
            gridApi = gridRef.current.api;
            gridColumnApi = gridRef.current.columnApi
        }
    }, [gridRef]);
    useEffect(() => {
        // redirectURL.post('/consignments/getContainersDetails', { "status": 18 }).then((resp) => {
        //     setContainerLegsData(resp.data.containerDetails)
        //     var work_order_level_data = resp.data.work_order_level_data
        //     setrowData(work_order_level_data)
        //     setoriginalData(work_order_level_data)
        // })
        if (gridApi && gridApi.current && gridApi.current.api) {
            gridApi.current.api.forEachNode((node) => {
                if (node.expanded) {
                    node.setExpanded(false);  // Collapse the row if expanded
                }
            });
        } else {
            console.log("gridApi is not initialized");
        }
        if (!loadData) {
            getCompletedPlantData()
        }
        // var records = props.data.container_report_new
        // if(records!='' && records!=undefined){
        //     // console.log(records,'105')
        //      records = records.filter(e=>e.pendency_gate_out<=0)
        //      records = sortByCreatedDate(records)
        //      if(localStorage.getItem('plant_code_list')!='' && localStorage.getItem('plant_code_list')!=undefined&&localStorage.getItem('plant_code_list')!='undefined'){
        //         var plantList = localStorage.getItem('plant_code_list')
        //         var plantListnames = plantList.split(',');
        //         var data_completed = []
        //         plantListnames.map((e)=>{
        //             var filtered_data = records.filter(f=>f.plant==e)
        //             data_completed.push(...filtered_data)
        //         })
        //         records = data_completed
        //         console.log(records,'records')
        //       }
        //     setrowData(records)
        // }
    }, [props.data])
    const getCompletedPlantData = async () => {
        setloadshow("show-m");
        setLoadData(true)
        await redirectURL.post('/consignments/getPlantCompletedData').then((resp) => { // Optimized api for Plant Data
            var records = resp.data
            if (records != '' && records != undefined) {
                records = records.filter(e => !e.pendency_gate_out || e.pendency_gate_out <= 0)
                records = sortByCreatedDate(records)
                if (localStorage.getItem('plant_code_list') != '' && localStorage.getItem('plant_code_list') != undefined && localStorage.getItem('plant_code_list') != 'undefined') {
                    var plantList = localStorage.getItem('plant_code_list')
                    var plantListnames = plantList.split(',');
                    var data_completed = []
                    plantListnames.map((e) => {
                        var filtered_data = records.filter(f => f.plant == e)
                        data_completed.push(...filtered_data)
                    })
                    records = data_completed
                }
                setrowData(records)
            }
            setloadshow("show-n");
             setTimeout(() => {
                setLoadData(false);
            }, 1000);
        }).catch(e => {
            console.log("Error,at :", e)
            setloadshow("show-n");
             setTimeout(() => {
                setLoadData(false);
            }, 1000);
        });
    };
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const updateBtn = () => {
        setshowAddForm("slide30")
        setoverly("show-m")
    }
    const onSelectWON = (e) => {
        console.log(e)
        setmanualUploadDetails(prev => ({
            ...prev,
            wrno: e
        }))
    }
    var updateContainerDetails = async (e) => {
        var params = {
            container_no: e.container_no,
            work_order_no: e.work_order_no,
            truck_no: e.truck_no,
        }
        var check = 0
        if (e.reporting_at_parking != undefined && e.reporting_at_parking != null && e.reporting_at_parking != "") {
            params.reporting_at_parking = e.reporting_at_parking
            check = 1
        }
        if (check != 0) {
            console.log(params, 'params')
            redirectURL.post('/consignments/updateContainerreportingtimeatplaza', params).then((resp) => {
                console.log(resp.data)
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Successfully Updated")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                } else {
                    setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
                }
            })
        } else {
            setshow(true)
            setbasicTitle("Please Provide Reporting Container Date")
            setbasicType("danger")
            setloadshow('show-n')
            setoverly('show-n')
        }

    }
    const onShowShipmentLegRouteMap = async (e) => {
        var params = {
          work_order_no: e.work_order_no,
          truck_no: e.truck_no,
          container_no: e.container_no
        };
      
        setloadshow('show-m');
        setoverly('show-m');
      
        try {
          const resp = await redirectURL.post('/consignments/getmapdata', params);
          console.log(resp.data);
          var records = resp.data.gps_df;
          console.log(records, '342');
      
          if (!Array.isArray(records)) {
            records = records.replace(/NaN/g, "0");
            records = JSON.parse(records);
          }
          console.log(records, '169');
          if (!records || records.length === 0) {
            setbasicTitle('No GPS Data Available');
            setbasicType('danger');
            setloadshow('show-n');
            setoverly('show-n');
          } else {
            var data = records[0];
            var allCoordinates = [];
            var allldbcoords = []
            var gps_route = data.gps_route;
            var ldb_route = data.ldb_route
            gps_route = JSON.parse(gps_route)
            ldb_route= JSON.parse(ldb_route)
            ldb_route.map((e) => {
                var lt = e.latitude || e.lat || 0; 
                var ln = e.longitude || e.lng || 0; 
      
                allldbcoords.push({
                  lat: lt,
                  lng: ln,
                });
              });
            console.log(gps_route.length, '365');
            console.log(ldb_route.length, '366');
            gps_route.map((e) => {
                var lt = e.latitude || e.lat || 0; 
                var ln = e.longitude || e.lng || 0; 
      
                allCoordinates.push({
                  lat: lt,
                  lng: ln,
                });
              });
            var truck_no;
            if (records.length > 0) {
              truck_no = records[0].truck_no;
            }
            console.log(allCoordinates, 'allCoordinates');
            if (allCoordinates.length > 0) {
                setoverly('show-m')
              setStateForMap((prev) => ({
                ...prev,
                sliderRouteTranslate: "slider-translate-60p",
                showDiv: 'show-m',
                container_no: truck_no,
                mapinfo: {
                  ...state.mapinfo,
                  coords: allCoordinates,
                  ldb_route:allldbcoords
                },
              }));
            } else {
              setbasicTitle('No GPS Data Available');
              setbasicType('danger');
              setloadshow('show-n');
              setshow(true);
            }
            setloadshow('show-n');
          }
        } catch (error) {
          console.error(error);
        }
      };
    // var onClickShowShipmentLegs = async (rownode) => {
    //     var commentHeaders = [
    //         {
    //             headerName: 'Requirement Id',
    //             field: "requirement_id",
    //             resizable: true,
    //             width: 180,
    //             filter: true
    //         },
    //         {
    //             headerName: 'Work Order No',
    //             field: "work_order_no",
    //             resizable: true,
    //             width: 180,
    //             filter: true
    //         },
    //         {
    //             headerName: 'Truck No',
    //             field: "truck_no",
    //             resizable: true,
    //             width: 180,
    //             filter: true
    //         },
    //         {
    //             headerName: 'Container No',
    //             field: "container_no",
    //             resizable: true,
    //             width: 180,
    //             filter: true

    //         },
    //         {
    //             headerName: 'Pickup Date',
    //             field: "pick_up_date",
    //             resizable: true,
    //             width: 180,
    //             filter: true,

    //             valueGetter: function (params) {
    //                 try {
    //                     if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
    //                         return getHyphenDDMMYYYY(params.data.pick_up_date);
    //                     } else {
    //                         return "";
    //                     }
    //                 } catch (e) {
    //                     // Handle the exception if needed
    //                 }
    //             },

    //         },
    //         {
    //             headerName: 'Shipping Seal',
    //             field: "shipping_seal",
    //             resizable: true,
    //             width: 180,
    //             filter: true,

    //         },
    //         {
    //             headerName: 'Invoice No',
    //             field: "invoice_no",
    //             resizable: true,
    //             width: 180,
    //             filter: true,
    //             editable: false,

    //         },
    //         {
    //             headerName: 'Invoice Date',
    //             field: "invoice_date",
    //             resizable: true,
    //             width: 180,
    //             filter: true,
    //             editable: false,
    //             cellEditor: DateEditor,
    //             valueGetter: function (params) {
    //                 try {
    //                     if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
    //                         return getHyphenDDMMYYYY(params.data.invoice_date);
    //                     } else {
    //                         return "";
    //                     }
    //                 } catch (e) {
    //                     // Handle the exception if needed
    //                 }
    //             },
    //             comparator: dateComparator1,
    //         },
    //         {
    //             headerName: 'RFID',
    //             field: "rfid",
    //             resizable: true,
    //             width: 180,
    //             filter: true,
    //             editable: false,
    //         },
    //         {
    //             headerName: 'Gate Out Time',
    //             field: "gate_out_time",
    //             resizable: true,
    //             width: 180,
    //             filter: true,
    //             editable: false,
    //             cellEditor: DateEditor,
    //             valueGetter: function (params) {
    //                 try {
    //                     if (params.data.gate_out_time != "" && params.data.gate_out_time != undefined) {
    //                         return getHyphenDDMMYYYY(params.data.gate_out_time);
    //                     } else {
    //                         return "";
    //                     }
    //                 } catch (e) {
    //                     // Handle the exception if needed
    //                 }
    //             },
    //             comparator: dateComparator1,
    //         },
    //         {
    //             headerName: 'Reporting Time At Parking Plaza',
    //             field: "reporting_at_parking",
    //             resizable: true,
    //             width: 210,
    //             filter: true,
    //             editable: false,
    //             cellEditor: DateEditor,
    //             valueGetter: function (params) {
    //                 try {
    //                     if (params.data.reporting_at_parking != "" && params.data.reporting_at_parking != undefined) {
    //                         return getHyphenDDMMYYYY(params.data.reporting_at_parking);
    //                     } else {
    //                         return "";
    //                     }
    //                 } catch (e) {
    //                     // Handle the exception if needed
    //                 }
    //             },
    //             comparator: dateComparator1,
    //         },
    //         {
    //             headerName: 'LEO Date',
    //             field: "leo_date",
    //             resizable: true,
    //             width: 180,
    //             filter: true,
    //             editable:false,
    //             cellEditor:DateEditor,
    //             valueGetter: function (params) {
    //                 try {
    //                     if (params.data.leo_date != "" && params.data.leo_date != undefined) {
    //                         return getHyphenDDMMYYYY(params.data.leo_date);
    //                     } else {
    //                         return "";
    //                     }
    //                 } catch (e) {
    //                     // Handle the exception if needed
    //                 }
    //             },
    //             comparator: dateComparator1,
    //         },  
    //         {
    //             headerName: 'Gate In Time At Port',
    //             field: "gate_in_time_port",
    //             resizable: true,
    //             width: 180,
    //             filter: true,
    //             editable:false,
    //             cellEditor:DateEditor,
    //             valueGetter: function (params) {
    //                 try {
    //                     if (params.data.gate_in_time_port != "" && params.data.gate_in_time_port != undefined) {
    //                         return getHyphenDDMMYYYY(params.data.gate_in_time_port);
    //                     } else {
    //                         return "";
    //                     }
    //                 } catch (e) {
    //                     // Handle the exception if needed
    //                 }
    //             },
    //             comparator: dateComparator1,
    //         },
    //         {
    //             headerName: 'Shipping On Board Date',
    //             field: "shipping_on_board_date",
    //             resizable: true,
    //             width: 180,
    //             filter: true,
    //             editable:false,
    //             cellEditor:DateEditor,
    //             valueGetter: function (params) {
    //                 try {
    //                     if (params.data.shipping_on_board_date != "" && params.data.shipping_on_board_date != undefined) {
    //                         return getHyphenDDMMYYYY(params.data.shipping_on_board_date);
    //                     } else {
    //                         return "";
    //                     }
    //                 } catch (e) {
    //                     // Handle the exception if needed
    //                 }
    //             },
    //             comparator: dateComparator1,
    //         },
    //         {
    //             headerName: 'BL No',
    //             field: "bl_no",
    //             resizable: true,
    //             width: 180,
    //             filter: true,
    //             editable:false,
    //         },
    //         {
    //             headerName: 'Bl Date',
    //             field: "bl_date",
    //             resizable: true,
    //             width: 180,
    //             filter: true,
    //             editable:false,
    //             cellEditor:DateEditor,
    //             valueGetter: function (params) {
    //                 try {
    //                     if (params.data.bl_date != "" && params.data.bl_date != undefined) {
    //                         return getHyphenDDMMYYYY(params.data.bl_date);
    //                     } else {
    //                         return "";
    //                     }
    //                 } catch (e) {
    //                     // Handle the exception if needed
    //                 }
    //             },
    //             comparator: dateComparator1,
    //         }
    //     ]
    //     var setdata = {
    //         detailGridOptions: {
    //             columnDefs: commentHeaders,
    //             overlayNoRowsTemplate: 'No rows to show',

    //         },
    //         getDetailRowData: async function (param) {
    //             param.successCallback([]);
    //             var row = param.data
    //             var parameter = {
    //                 work_order_no: row.work_order_no
    //             }
    //             // await redirectURL.post("/consignments/truckslegsdata",parameter).then(async (response) => {
    //             //     console.log(response.data,'response')
    //             //     var records = response.data 
    //             var records = containerLegsData.filter(e => e.work_order_no == row.work_order_no)
    //             param.successCallback(records);
    //             // }).catch(function (error) {
    //             //     console.log(error);
    //             // })
    //         },
    //         masterDetail: true
    //     }
    //     dispatch({ type: 'SET_DETAIL_RENDERER_PARAMS', payload: setdata });
    //     setTimeout(() => {
    //         if (rownode.column.colDef.field == 'containerLegs') {
    //             rownode.node.setExpanded(!rownode.node.expanded);

    //         }
    //         else {

    //             rownode.node.setExpanded(false);
    //         }
    //     }, 0)
    // }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')

    }
    var onClickShowShipmentLegs = async (rownode) => {          
        var commentHeaders = [
            {
                headerName: "",
                field: "",
                resizable: true,
                width: 60,
                filter: true,
                // cellRendererFramework: LegRouteActiveTruckMap,
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <button onClick={() => 9(params.data)} className="custom-btn label label-success">
                                <i className="icofont icofont-map-pins f20"></i>
                            </button>
                        </div>
                    );
                },
            },
            {
                headerName: 'Requirement Id',
                field: "requirement_id",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Work Order No',
                field: "work_order_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Truck No',
                field: "truck_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Container No',
                field: "container_no",
                resizable: true,
                width: 180,
                filter: true

            },
            {
                headerName: 'Pickup Date',
                field: "pick_up_date",
                resizable: true,
                width: 180,
                filter: true,

                valueGetter: function (params) {
                    try {
                        if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.pick_up_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1, 
            },
            {
                headerName: 'Shipping Seal',
                field: "shipping_seal",
                resizable: true,
                width: 180,
                filter: true,

            },
            {
                headerName: 'Plant Gate in Time',
                field: "gate_in_time",
                resizable: true,
                width: 180,
                filter: true,
                // editable: true,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_in_time != "" && params.data.gate_in_time != undefined) {
                            return getHyphenYYYYMMDDHHMM(params.data.gate_in_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'Invoice No',
                field: "invoice_no",
                resizable: true,
                width: 180,
                filter: true,

            },
            {
                headerName: 'Invoice Date',
                field: "invoice_date",
                resizable: true,
                width: 180,
                filter: true,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
                            return getHyphenYYYYMMDDHHMM(params.data.invoice_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'RFID',
                field: "rfid",
                resizable: true,
                width: 180,
                filter: true,
                // editable: true,
            },
            {
                headerName: 'Plant Gate Out Time',
                field: "gate_out_time",
                resizable: true,
                width: 180,
                filter: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_out_time != "" && params.data.gate_out_time != undefined) {
                            return getHyphenYYYYMMDDHHMM(params.data.gate_out_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
        ]
        var setdata = {
            detailGridOptions: {
                columnDefs: commentHeaders,
                overlayNoRowsTemplate: 'No rows to show',

            },
            getDetailRowData: async function (param) {
                param.successCallback([]);
                var row = param.data
                var parameter = {
                    work_order_no: row.work_order_no
                }
                await redirectURL.post("/consignments/truckslegsdata", parameter).then(async (response) => {
                    var records = response.data
                    // var records = containerLegsData.filter(e =>e.work_order_no==row.work_order_no)
                    param.successCallback(records);
                }).catch(function (error) {
                })
            // var records = props.data.container_new_1
            // if(records!='' && records!=undefined){
            //     records = records.filter(e=>e.work_order_no==row.work_order_no)
            // }
                // param.successCallback(records);
            },
            masterDetail: true
        }
        dispatch({ type: 'SET_DETAIL_RENDERER_PARAMS', payload: setdata });
        setTimeout(() => {
            if (rownode.column.colDef.field == 'containerLegs') {
                rownode.node.setExpanded(!rownode.node.expanded);

            }
            else {

                rownode.node.setExpanded(false);
            }
        }, 0)
    }
    const onsubmitdata = (e) => {
        e.preventDefault()
        var trucks = manualUploadDetails.trucks
        var truckList = []
        trucks.map((e) => {
            truckList.push(e.value)
        })
        console.log(truckList, 'truckList')
        var wrno = manualUploadDetails.wrno.value
        console.log(originalData, 'originalData')
        var record = originalData.filter(e => e.work_order_no == wrno)
        record = record[0]

        var containerCount = record.container_count
        console.log(containerCount, 'containerCount')
        var params = {
            work_order_no: wrno,
            trucks: truckList
        }
        if (containerCount == trucks.length) {
            redirectURL.post('/consignments/updateTrucksDetails', params).then((resp) => {

            })
        }
        else {

        }
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const onSelectConfirm = (e) => {
        if (e.value == 'yes') {
            setcheckfortruck(1)
        }
        else {
            setshowAddForm('')
            setoverly('show-n')
        }
    }
    const handleTrucksList = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            trucks: e
        }))


    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    const summaryViewcols = [
        {
            headerName: "",
            field: "containerLegs",
            width: 60,
            pinned: 'left',
            cellRenderer: 'ConsignmentTrucksLegs'
        },
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 130,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 200,
            filter: true,
            resizable: true,
        },

        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
            comparator: dateComparator1, 
        },
        {
            headerName: "Booking No",
            field: "booking_no",
            width: 160,
            filter: true,
            resizable: true,
        },

        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
        },

        // {
        //     headerName: "Shipping Bill No",
        //     field: "shipping_bill_no",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Shipping Bill Date",
        //     field: "shipping_bill_date",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.shipping_bill_date != "" && params.data.shipping_bill_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.shipping_bill_date);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },

    ]
    const detailedviewcols = []
    const columnwithDefs = summaryViewcols
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="" style={{ margin: '10px', marginTop: '25px', width: "100%", height: "478px" }}>
            <div id="idgrid" style={{ width: "100%", height: "478px"}} className="ag-theme-balham">
                <AgGridReact
                    columnDefs={columnwithDefs}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        editable: false,
                        resizable: true,
                        menuTabs: ['filterMenuTab']
                        // rowHeight: 80,
                    }}
                    // paddingTop={10}
                    // rowHeight={70}
                    // headerHeight = {70}
                    rowData={rowData}
                    suppressRowClickSelection={true}
                    detailCellRendererParams={state.detailCellRendererParams}
                    frameworkComponents={{CountryFlagComponent:CountryFlagComponent , ConsignmentTrucksLegs: ConsignmentTrucksLegs }}
                    enableCellChangeFlash={true}
                    suppressCellFlash={true}
                    enableRangeSelection={true}
                    paginationPageSize={50}
                    rowSelection={'multiple'}
                    masterDetail={true}
                    pagination={true}
                    sideBar={{
                        toolPanels: [
                            {
                                id: "columns",
                                labelDefault: "Columns",
                                labelKey: "columns",
                                iconKey: "columns",
                                toolPanel: "agColumnsToolPanel"
                            },
                            {
                                id: "filters",
                                labelDefault: "Filters",
                                labelKey: "filters",
                                iconKey: "filter",
                                toolPanel: "agFiltersToolPanel"
                            }
                        ]
                    }}
                    statusBar={{
                        statusPanels: [
                            {
                                statusPanel: "agTotalAndFilteredRowCountComponent",
                                align: "left"
                            },
                            {
                                statusPanel: "agTotalRowCountComponent",
                                align: "center"
                            },
                            { statusPanel: "agFilteredRowCountComponent" },
                            { statusPanel: "agSelectedRowCountComponent" },
                            { statusPanel: "agAggregationComponent" }
                        ]
                    }}
                    ref={gridRef}
                    context={{onClickShowShipmentLegs }}
                />

            </div>
            </div>

            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>



    )
}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });
    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
    });
}
function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
// function dateComparator1(date1, date2) {
//     if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
//         var date_1 = getMonthNumber(date1.split("-")[1]);
//         date1 = date1.replace(date1.split("-")[1], date_1);
//         var date_2 = getMonthNumber(date2.split("-")[1]);
//         date2 = date2.replace(date2.split("-")[1], date_2);
//         var date1Number = monthToComparableNumber1(date1);
//         var date2Number = monthToComparableNumber1(date2);
//         if (date1Number === null && date2Number === null) {
//             return 0;
//         }
//         if (date1Number === null) {
//             return -1;
//         }
//         if (date2Number === null) {
//             return 1;
//         }
//         console.log(date1Number, date2Number, "comparator")
//         return date1Number - date2Number;
//     }
// }
// function monthToComparableNumber1(date) {
//     if (date === undefined || date === null || date.length !== 10) {
//         return null;
//     }
//     var yearNumber = date.substring(6, 10);
//     var monthNumber = date.substring(3, 5);
//     var dayNumber = date.substring(0, 2);
//     var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
//     return result;
// }
function dateComparator1(date1, date2) {
    if (!date1 || !date2) return 0;
    const date1Number = monthToComparableNumber1(date1);
    const date2Number = monthToComparableNumber1(date2);
    if (date1Number === null && date2Number === null) {
        return 0;  // Both dates are invalid, return equal
    }
    if (date1Number === null) {
        return -1;  // If date1 is invalid, consider it smaller
    }
    if (date2Number === null) {
        return 1;  // If date2 is invalid, consider it larger
    }
    return date1Number - date2Number;
}
// Helper function to convert date (DD-MM-YYYY) into a comparable numeric value
function monthToComparableNumber1(date) {
    if (!date || date.length !== 10) {
        return null;  // Invalid date format
    }
    // Extract year, month, and day from the string
    const day = parseInt(date.substring(0, 2), 10);
    const month = parseInt(date.substring(3, 5), 10);
    const year = parseInt(date.substring(6, 10), 10);
    // Return a numeric value in the format YYYYMMDD for comparison
    return year * 10000 + month * 100 + day;
}
function loadDateTimeScript2() {
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        console.log(this.eInput)
        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}
function DateEditor() { }
DateEditor.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        showTime: false,
    });
};
DateEditor.prototype.getGui = function () {
    return this.eInput;
};
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};
DateEditor.prototype.destroy = function () {
};
DateEditor.prototype.isPopup = function () {
    return false;
};
function removeDuplicates(arr, prop) {
    const seen = new Set();
    return arr.filter(obj => {
        const propValue = obj[prop];
        const propString = typeof propValue === 'object' ? JSON.stringify(propValue) : propValue;
        if (seen.has(propString)) {
            return false;
        }
        seen.add(propString);
        return true;
    });
}
function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
      if (arr.length > 0) {
        const comparison = (a, b) => {
          const dateA = new Date(a.modified_date);
          const dateB = new Date(b.modified_date);
          if (descending) {
            return dateB - dateA; // Ascending order
          } else {
            return dateA - dateB; // Descending order
          }
        };
          arr.sort(comparison);
        return arr; 
      }
    } else {
      return arr; 
    }
  }
export default ClosedComponentForPlant