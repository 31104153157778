import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import redirectURL from "../redirectURL";

export default class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isNav: 0,
			email: "",
			password: "",
			setloginpage: '',
			errmsg: '',
			errorContainer: false
		}
		this.changeHandler = this.changeHandler.bind(this);
	}
	componentDidMount() {
		redirectURL.post("/login/welcome")
			.then((response) => {
				console.log("response ", response.data)
			})
		var loginPage = ''
		if (localStorage.getItem('user_type') == 'admin') {
			loginPage = '/exportoperationscontroltower'
		}
		else if (localStorage.getItem('user_type') == 'cha') {
			loginPage = '/booking/cha'
		}
		else if (localStorage.getItem('user_type') == 'plant') {
			loginPage = '/booking/plant'
		}
		else if (localStorage.getItem('user_type') == 'transporter') {
			loginPage = '/booking/transporter'
		}
		this.setState({
			setloginpage: loginPage
		})
	}
	onClickGoToDashboard() {
		redirectURL.post("/login/welcome")
			.then((response) => {
				console.log("response ", response.data)
				localStorage.setItem("token", "1xEdr125dg6577dfgjseXbnddgxuy2kg")
				this.setState({
					isNav: 1
				});
			})
	}
	changeHandler(event) {
		var name = event.target.name;
		var value = event.target.value;
		this.setState({
			[name]: value
		})
	}
	handleForgotPasswordClick() {
		window.location.href = '/resetpassword'
	};
	formSubmit(event) {
		event.preventDefault();
		var params = {
			e: this.state.email,
			d: this.state.password
		}
		redirectURL.post("/verifyUser", params)
			.then((response) => {
				if (response.data.status == 'fail') {
					console.log('68')
					this.setState({
						errmsg: response.data.message,
						errorContainer: true
					})
				}
				else {
					console.log("login ", response.data)
					localStorage.setItem("email", response.data.user[0].email);
					localStorage.setItem("token", response.data.token);
					localStorage.setItem("user_type", response.data.user[0].user_type);
					localStorage.setItem("role", response.data.user[0].role);
					localStorage.setItem("user_code", response.data.user[0].user_code);
					localStorage.setItem("user_name", response.data.user[0].username);
					localStorage.setItem("plant_code", response.data.user[0].plant_code);
					localStorage.setItem("tpt_code", response.data.user[0].tpt_code);
					localStorage.setItem("tpt_name", response.data.user[0].tpt_name)
					localStorage.setItem("plant_code_list", response.data.user[0].plant_code_list)
					localStorage.setItem("user_role", response.data.user[0].user_role)
					localStorage.setItem("userid",response.data.user[0]._id)
					this.setState({
						isNav: 1
					})
				}
			})
	}
	render() {
		if (this.state.isNav === 1) {
			if (localStorage.getItem('user_type') == 'admin') {
				return <Navigate to={'/exportoperationscontroltower'} push={true} />
			}
			else if (localStorage.getItem('user_type') == 'cha') {
				return <Navigate to={'/booking/cha'} push={true} />
			}
			else if (localStorage.getItem('user_type') == 'plant') {
				return <Navigate to={'/booking/plant'} push={true} />
			}
			else if (localStorage.getItem('user_type') == 'transporter') {
				return <Navigate to={'/booking/transporter'} push={true} />
			}
		}
		return (
			<div className="row">
				<div className="col-xl-12 col-lg-12 col-md-9">
					<div className="card o-hidden border-0">
						<div className="card-body1 p-0">
							<div className="row">
								<div className=" col-xl-8 col-lg-8">
									{/*
									<img src={require("../../assets/icons/enmovil.png")} /> 
								*/}
									<img style={{ width: "100%", maxHeight: "100vh" }} src={require("../../assets/icons/bajaj_login_image.jpg")} />
									{/* <span style={{position:"absolute",left:"0%", top:"0%"}}>
										
									</span> */}
								</div>
								<div className=" col-xl-4 col-lg-4" style={{ padding: "30px", paddingTop: "45px", paddingBottom: "45px" }}>
									<div className='login-card'>
										<div className="p-5" style={{ padding: "5rem" }}>
											<div className="text-center" style={{ marginBottom: "75px" }}>
												<img style={{ width: "100%", maxHeight: "100vh" }} src={require("../../assets/icons/bajaj_bikes_logo.png")} />
											</div>
											{this.state.errorContainer ? <div className='err-container mb-30p'>
												<h1 className='err-head'>{this.state.errmsg}</h1>
											</div> : ''}
											<form className="user" onSubmit={this.formSubmit.bind(this)}>
												<div className="form-group">
													<input type="email" className="form-control form-control-user"
														id="exampleInputEmail" name="email" onChange={this.changeHandler} aria-describedby="emailHelp"
														placeholder="Enter Email Address..." />
												</div>
												<div className="form-group">
													<input type="password" className="form-control form-control-user"
														id="exampleInputPassword" name="password" onChange={this.changeHandler} placeholder="Password" />
												</div>
												{/* <div className="form-group">
													<div className="custom-control custom-checkbox small">
														<input type="checkbox" className="custom-control-input" id="customCheck" />
														<label className="custom-control-label" for="customCheck">Remember
															Me</label>
													</div>
												</div> */}
												<button type="submit" className="btn btn-primary btn-user btn-block">
													Log in
												</button>
											</form>
											<hr />
											<div className="text-center">
											<a href={"/resetpassword"} className="small">Forgot Password</a>
											</div>
										</div>
									</div>

									<div className='login-footer' style={{ marginTop: "55px" }}>
										<div className='text-center'>
											<span style={{ fontWeight: "500", marginRight: "10px", fontSize: "14px", fontFamily: "Poppins", verticalAlign: "middle" }}>Powered By</span><img src={require("../../assets/icons/mask_group.png")} style={{ height: "27px" }} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		)
	}
}