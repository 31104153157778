
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
const UpdateShippingBillDetailsBtn = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        console.log(props,"props10")
         props.context.onClickshippingbillbtn(props);
    };
    return (
        <div className="map-icon-component" style={{cursor: "pointer"}}>
            {/* {props.data.shipping_new==1?<img onClick={handleRouteClick}  src={require("../../assets/images/Update.png")} style={{height:'20px',marginLeft:'30px'}}/>:<img onClick={handleRouteClick}  src={require("../../assets/images/update-gray.png")} style={{height:'20px',marginLeft:'30px'}}/>} */}
            {((props.data.at_plant_modified!=undefined && props.data.at_plant_modified==1)||(props.data.ib_plant_gate_in_modified!=undefined && props.data.ib_plant_gate_in_modified==1)||(props.data.tpt_container_modified!=undefined && props.data.tpt_container_modified==1)||(props.data.tpt_trucks_modified!=undefined && props.data.tpt_trucks_modified==1)||(props.data.ib_tpt_modified!=undefined && props.data.ib_tpt_modified==1)||(props.data.cha_do_modified!=undefined && props.data.cha_do_modified==1)||(props.data.ib_stuffing_modified!=undefined && props.data.ib_stuffing_modified==1) || (props.data.modified_plant==1 && props.data.modified_plant!=undefined))?<img  src={require("../../assets/images/update-gray.png")} style={{height:'15px',}}/>:<img onClick={handleRouteClick}  src={require("../../assets/images/Update.png")} style={{height:'15px'}}/>}
            
 {/* <button onClick={(e) => handleRouteClick(e)} className={bgclr}>
                           Update Shipping Bill Details
                        </button> */}
            {/* <button onClick={handleRouteClick} className="custom-btn label label-success" title="Shipment Legs"><i style={{color:"#FF0000"}} className="icofont icofont-site-map f25"></i> </button> */}
        </div>
    );
};
export default UpdateShippingBillDetailsBtn;
