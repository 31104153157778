import React, { useState, useEffect, useRef } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import Select from "react-select";
import Datepicker from "../layouts/datepicker";
import moment from "moment";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY } from '../common/utils';
import SearchIcon from "../../assets/icons/search-icon.svg";
import RefreshIcon from "../../assets/images/refresh-icon.svg";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import CountryFlagComponent from '../layouts/countryFlag';
import DestinationFlagComponent from '../layouts/destinationCountryFlag';
import ViewDOdetailsComponent from "../layouts/viewDOdetails";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import { fetchGrid } from "../fetchGridStructure"
import redirectURL from "../redirectURL";
const UpTimeGPSData = () => {
    const gridApi = useRef(null);
    const gridColumnApi = useRef(null);

    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [rowData, setrowData] = useState([])
    var [selectedData, setselectedData] = useState([])
    var [filterdata, setfilterdata] = useState([])
    var [checkinput, setcheckinput] = useState(1)
    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [checkresetfilter, setcheckresetfilter] = useState(0)
    var [originalData, setoriginalData] = useState([])
    var [show, setshow] = useState(false)
    var [reload, setreload] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [overly, setoverly] = useState('show-n')
    var [loadshow, setloadshow] = useState('show-n')
    var [tooltipVisible, settooltipVisible] = useState(true)
    var typeofselection = [
        { label: 'Stuffing Date', value: 'stuffing_date' },
        { label: 'Plant', value: 'plant' },
        { label: 'CHA', value: 'cha' },
        { label: 'Ship to Country', value: 'ship_to_country' },
    ]

    // var [woReleaseDate, setWoReleaseDate] = useState({
    //     wo_release_from_date: '',
    //     wo_release_to_date: ''
    // })
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: moment().format('DD-MM-YYYY'),
        wo_release_to_date: moment().add(2, 'days').format('DD-MM-YYYY')
    });
    var [plant, setPlant] = useState({ 'label': 'All', 'value': 'All' });
    var [cha, setCha] = useState({ label: 'All', value: 'All' });
    var [shiptocountry, setShiptoCountry] = useState({ label: 'All', value: 'All' });
    var [plantList, setPlantList] = useState([]);
    var [chaList, setChaList] = useState([]);
    var [shipToCountryList, setShipToCountryList] = useState([]);
    const defaulColumnDefs = [
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width: 250,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Vehicle Count",
            field: "vehicle_count",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Total Days",
            field: "total_days",
            width: 100,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Active",
            field: "active",
            width: 80,
            filter: true,
            resizable: true,
            // cellRenderer: 'DestinationFlagComponent'
        },
        {
            headerName: "Inactive",
            field: "inactive",
            width: 80,
            filter: true,
            resizable: true,
            // cellRenderer: 'DestinationFlagComponent'
        },
        {
            headerName: "Battery Disconnected",
            field: "battery_disconnected",
            width: 160,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Others",
        //     field: "others",
        //     width: 120,
        //     filter: true,
        //     resizable: true,
        // },
        {
            headerName: "Total Uptime Days",
            field: "total_uptime_days",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Uptime %",
            field: "uptime%",
            width: 110,
            filter: true,
            resizable: true,
        },
    ]    
    const [summaryViewcols, setsummaryViewcols] = useState(defaulColumnDefs)
    useEffect(() => {
        if (gridApi.current) {
            getUserGridState();
        }
    }, [gridApi]);
    useEffect(() => {
        //console.log(woReleaseDate, 'woReleaseDate')
        var sdate = moment(woReleaseDate.wo_release_from_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        var edate = moment(woReleaseDate.wo_release_to_date, 'DD-MM-YYYY').format('YYYY-MM-DD');

        var params = {
            'from_date': sdate,
            'to_date': edate
        }
        //console.log(params, '143')
        setloadshow("show-m")
        setoverly("show-m")
        redirectURL.post('/consignments/getuptimegpsdata', params).then((resp) => {
            try {
                var records = resp.data;
                //console.log(records, '75');
                setrowData(records)
                setloadshow("show-n")
                setoverly("show-n")

            } catch (e) {
                console.error('Error:', e);
                setloadshow("show-n")
                setoverly("show-n")
            }
        });

    }, [])
    const onGridReady = params => {
        gridApi.current = params;
        gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
    const handleInputfilter = (e) => {
        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
    }
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: window.location.pathname ,userId:localStorage.getItem("userid")})
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols = fetchGrid(summaryViewcols, grid[0].gridcolumns)
                //console.log("oCols", oCols)

                // setsummaryViewcols(oCols);
            })
    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols = fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            //console.log(e);
        }
    };
    const onGridState = () => {
        ////console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */

        window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


    }
    const handleFilterOptions = (e) => {
        if (e.value == 'stuffing_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        if (val != '' && val != undefined) {
            var filteredData
            if (typeoffield == 'ship_to_country') {
                filteredData = originalData.filter(item => item.ship_to_country.toLowerCase() == val.toLowerCase());
            }
            else if (typeoffield == 'plant') {
                filteredData = originalData.filter(item => item.plant.toLowerCase() == val.toLowerCase());
            }
            else {
                //console.log('96')
                filteredData = originalData.filter(item => item.cha.toLowerCase() == val.toLowerCase());
            }
            if (filteredData != '' && filteredData != undefined) {
                if (localStorage.getItem('role') == 'tpt') {
                    filteredData = filteredData.filter(k => k.transporter_name == localStorage.getItem('tpt_name'))
                }
            }
            setrowData(filteredData)
            setcheckresetfilter(1)
        }
        else {
            setbasicTitle('Please Enter Value')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    var onClickSaveGridState = () => {
        try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = '/uptimegpsdata'
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: window.location.pathname,
                screentitle: screenpage
            }
            //console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    //console.log("Error ", e)
                })
            //console.log('hit')
        }
        catch (e) {
            //console.log(e)
        }
    }
    const resetState = () => {
        try {
            // Reset columns to their initial state (order, visibility, width)
            setsummaryViewcols(defaulColumnDefs)
            const initialColumnState = defaulColumnDefs.map(col => ({
                colId: col.field,
                hide: col.hide || false,
                ...col
            }));
            // Apply the column state to reset order and visibility
            gridApi.current.columnApi.applyColumnState({
                state: initialColumnState,
                applyOrder: true  // This ensures that the column order is also reset
            });
            gridApi.current.api.refreshCells();
            // After the reset, you may want to fetch the user grid state from the backend
            const reqparams = {
                userId: localStorage.getItem("userid"),
                screenurl: window.location.pathname,
                screentitle: '/uptimegpsdata',  // Adjust to your actual screen title
            };
            redirectURL.post("/consignments/removeUserGridState", reqparams)
                .then((response) => {
                    setshow(true);
                    setbasicTitle("Successfully reset default grid layout");
                    setbasicType("success");
                    // Optionally, reload grid state from the backend (if needed)
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000);
                })
                .catch((error) => {
                    setshow(true);
                    setbasicTitle("Error resetting grid layout");
                    setbasicType("error");
                    console.error('Error resetting grid state:', error);
                });
        } catch (error) {
            console.error('Unexpected error in resetState:', error);
        }
    };
    // const onClickSaveGridState = () => {
    //     try {
    //         if (gridApi.current) {
    //             const columnState = gridColumnApi.current.getColumnState();
    //             const columnGroupState = gridColumnApi.current.getColumnGroupState();
    //             const sortState = gridApi.current.api.getSortModel(); // Accessing getSortModel from gridApi.current.api
    //             const filterState = gridApi.current.api.getFilterModel();

    //             var screenpage = '/uptimegpsdata';
    //             let reqparams = {
    //                 gridcolumns: columnState,
    //                 gridgroup: columnGroupState,
    //                 gridcolsort: sortState,
    //                 gridcolfilter: filterState,
    //                 userId: localStorage.getItem("userid"),
    //                 screenurl: window.location.pathname,
    //                 screentitle: screenpage
    //             };

    //             redirectURL.post("/consignments/saveGridStates", reqparams)
    //                 .then((response) => {
    //                     setshow(true);
    //                     setbasicTitle("Successfully saved grid layout");
    //                     setbasicType("success");
    //                     setTimeout(() => {
    //                         getUserGridState();
    //                     }, 2000);
    //                 })
    //                 .catch(function (e) {
    //                     //console.log("Error ", e);
    //                 });
    //         } else {
    //             console.error('Grid API is .');
    //         }
    //     } catch (e) {
    //         //console.log(e);
    //     }
    // };

    const hideTooltip = () => {
        settooltipVisible(false)
    }
    const filterDatafordates = () => {
        setloadshow('show-m')
        setoverly("show-m")

        var sdate = moment(woReleaseDate.wo_release_from_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        var edate = moment(woReleaseDate.wo_release_to_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        var params = {
            'from_date': sdate,
            'to_date': edate
        }
        //console.log(params, '143')
        redirectURL.post('/consignments/getuptimegpsdata', params).then((resp) => {
            try {
                var records = resp.data;
                //console.log(records, '75');
                setrowData(records)
                setloadshow('show-n')
                setoverly("show-n")


            } catch (e) {
                console.error('Error:', e);
                setloadshow('show-n')

            }
        });
    }
    const handleDatesSelected = (e) => {
        //console.log(e, "8989")

        const date = moment(e).format('DD-MM-YYYY');

        //console.log(date, "datemoment")

        setWoReleaseDate(prev => ({
            ...prev,
            wo_release_from_date: date
        }))

    };
    const handleDatesSelected1 = (e) => {
        //console.log(e, "8989")
        const date = moment(e).format('DD-MM-YYYY');

        //console.log(date, "datemoment")
        setWoReleaseDate(prev => ({
            ...prev,
            wo_release_to_date: date
        }))
    };
    const onClickDOdetails = (params) => {
        //console.log('163', params)
        //console.log(params.data.attachmentFileName)
        if (params.data.attachmentFileName != undefined && params.data.attachmentFileName != "") {
            var fileparams = {
                attachmentFileName: params.data.attachmentFileName,
                work_order_no: params.data.work_order_no
            }
            redirectURL.post("/consignments/downloadDOFileFile", fileparams, {
            }).then((response) => {
                const imageURL = response.data;

                window.open(imageURL, '_blank');
            }).catch(function (error) {
                //console.log(error);
            })
        }
    }
    const refreshData = () => {
        setcheckresetfilter(0)
        setrowData(originalData)
        setselectedval(prev => ({
            ...prev,
            inputval: ''
        }))
    }
    const columnDefs = summaryViewcols

    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }

    // const onChangeHandler=(e)=>{
    //     if (e.value=="plant"){
    //         setPlant(e);
    //     }else if (e.value=="cha"){
    //         setCha(e);
    //     }else if (e.value=="shiptocountry"){
    //         setShiptoCountry(e);
    //     }

    // }
    const filtersData = (e) => {
        e.preventDefault();
        var data = selectedData
        //console.log(data, '723')
        if (plant.value != '' && plant.value != undefined && plant.value != 'All') {
            data = data.filter(f => f.plant == plant.value)
        }
        else {
            data = data
        }
        //console.log(cha.value, '723')
        if (cha.value != '' && cha.value != undefined && cha.value != 'All') {
            data = data.filter(f => f.cha == cha.value)
        } else {
            data = data
        }
        //console.log(data, '723')

        if (shiptocountry.value != '' && shiptocountry.value != undefined && shiptocountry.value != 'All') {
            data = data.filter(f => f.ship_to_country == shiptocountry.value)
        } else {
            data = data
        }
        //console.log(data, '723')
        if (data != '' && data != undefined) {
            if (localStorage.getItem('role') == 'tpt') {
                data = data.filter(k => k.transporter_name == localStorage.getItem('tpt_name'))
            }
        }
        setrowData(data);
        // setPlant({'label':'','value':''})
        // setCha({'label':'','value':''})
        // setShiptoCountry({'label':'','value':''})
        // setPlant("");
        // setCha("");
        // setShiptoCountry("");

        // const filtersDataForm = {
        //     "Plant":plant,
        //     "CHA":cha,
        //     "ShiptoCountry":shiptocountry
        // }
        // //console.log(filtersDataForm);
        // var filteredData = originalData.filter((item)=>{
        //     return item.plant==plant || item.cha==cha || item.ship_to_country==shiptocountry});
        // setrowData(filteredData);

    }
    const restrictToOther = (e) => {
        e.target.value = e.target.value.replace()
    }

    const onClickHide = () => {
        setloadshow('show-n')
    }

    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="form-group d-flex justify-content-between">
                    <h1 className="heading mt-30p">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>GPS Uptime Report
                    </h1>
                </div>
                <div className="card m-10p">
                    <div className="card-body" id="counterCardBody">
                        <div className="row">
                            <div className="col-sm-8 checkForDateFilter_gap">
                                <div className="row">

                                    <div className="form-group col-xl-4 col-lg-4">
                                        <div>
                                            <label> From Date </label>
                                            <Datetime
                                                key={woReleaseDate.wo_release_from_date}
                                                // onKeyDown ={(e)=> restrictToOther(e)}
                                                placeholder="Date"
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                name="from_date"
                                                inputProps={{ readOnly: 'true' }}
                                                value={woReleaseDate.wo_release_from_date}
                                                dateFormat="DD-MM-YYYY"
                                                id='from_date'
                                                timeFormat={false}

                                                onChange={(e) => handleDatesSelected(e)}
                                                required />
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <div>
                                            <label> To Date </label>
                                            <Datetime
                                                key={woReleaseDate.wo_release_to_date}
                                                placeholder="Date"
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                name="to_date"
                                                inputProps={{ readOnly: 'true' }}
                                                value={woReleaseDate.wo_release_to_date}
                                                dateFormat="DD-MM-YYYY"
                                                id='to_date'
                                                timeFormat={false}
                                                onChange={(e) => handleDatesSelected1(e)}
                                                required />
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <button className="btn btn-success mt-30p" onClick={filterDatafordates}>Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
    <div className="row">
        <div className="col d-flex justify-content-end align-items-center">
        <div className="info-icon" onClick={hideTooltip} style={{margin:'15px'}}>
                <i className="icofont icofont-info-circle"></i>
                {tooltipVisible && (
    <div className="info-tooltip1" style={{marginRight:'180px', color: "black", border: "3px solid #809fff", width: "300px", }}>
        <p>Vehicle Count - No.of trucks for the transporter<br />
            Active - No.of Trucks with Active GPS<br />
            Inactive - No GPS or Battery greater than 10<br />
            Battery Disconnected - Battery less than 10<br />
            Total Uptime Days - active + battery_disconnected</p>
    </div>
)}
            </div>
            <a href="/reportscreen" className="f12 mr-5p btn btn-default"  style={{margin:'15px'}}>Back</a>
            <button
                                        type="button"
                                        title="Save Grid Layout"
                                        name="savegrid"
                                        className="btn btn-danger action_btn"
                                        style={{height: "32px", marginTop: "30px", paddingBottom:"30px" }}

                                        onClick={onClickSaveGridState}>
                                        <i className="f12 icofont icofont-save"></i>
                                        </button>
                                        <button
                        style={{ height: "32px", marginTop: "30px", paddingBottom: "30px", marginLeft: "4px" }}
                        title="Reset Default Layout"
                        className="float-right btn white btn-info" onClick={resetState}>
                        <i className="icofont icofont-refresh"></i>
                    </button>

            

        </div>
    </div>
</div>

                        </div>

                        <div className="row" >
                            <div className="col-sm-12" >
                                <div id="idgrid" style={{ width: "100%", height: "450px" }} className="ag-theme-balham">
                                    <AgGridReact
                                        ref={gridApi}
                                        // modules={AllModules}
                                        columnDefs={columnDefs}
                                        defaultColDef={{
                                            sortable: true,
                                            filter: true,
                                            editable: false,
                                            resizable: true,
                                            menuTabs: ['filterMenuTab']
                                        }}
                                        rowData={rowData}
                                        suppressRowClickSelection={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        enableRangeSelection={true}
                                        paginationPageSize={100}
                                        onGridReady={onGridReady}
                                        onGridState={onGridState}
                                        rowSelection={'multiple'}
                                        masterDetail={true}
                                        pagination={true}
                                        frameworkComponents={{ CountryFlagComponent: CountryFlagComponent, DestinationFlagComponent: DestinationFlagComponent }}
                                        sideBar={{
                                            toolPanels: [
                                                {
                                                    id: "columns",
                                                    labelDefault: "Columns",
                                                    labelKey: "columns",
                                                    iconKey: "columns",
                                                    toolPanel: "agColumnsToolPanel"
                                                },
                                                {
                                                    id: "filters",
                                                    labelDefault: "Filters",
                                                    labelKey: "filters",
                                                    iconKey: "filter",
                                                    toolPanel: "agFiltersToolPanel"
                                                }
                                            ]
                                        }}
                                        statusBar={{
                                            statusPanels: [
                                                { statusPanel: "agFilteredRowCountComponent" },
                                                { statusPanel: "agSelectedRowCountComponent" },
                                                { statusPanel: "agAggregationComponent" }
                                            ]
                                        }}
                                        gridOptions={{
                                            icons: {
                                                next: `<img src="${customNextIcon}" alt="Next" />`,
                                                previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                                first: `<img src="${customFirstIcon}" alt="First" />`,
                                                last: `<img src="${customLastIcon}" alt="Last" />`,
                                            },
                                        }}
                                        context={{ onClickDOdetails }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={onClickHide}></div>
            </div>



        </>

    )
}
function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
        if (arr.length > 0) {
            const comparison = (a, b) => {
                const dateA = new Date(a.modified_date);
                const dateB = new Date(b.modified_date);
                if (descending) {
                    return dateB - dateA; // Ascending order
                } else {
                    return dateA - dateB; // Descending order
                }
            };
            arr.sort(comparison);
            return arr;
        }
    } else {
        return arr;
    }
}
function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}

export default UpTimeGPSData;