import React, { Component } from "react";
import axios from 'axios';
import redirectURL from '../redirectURL'; // Assuming redirectURL is an axios instance
class EnmUserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = { successLogin: 0 };
    }
    async componentDidMount() {
        var main_token = window.location.pathname.split("/");
        const token = main_token[main_token.length-1]
        var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        var usrtoken = ppwwdd(token)
        const query = {
            token: token
        };
        try {
            const response = await redirectURL.post("/authenticateUserToken", query);
            console.log("After login 19", response);
            if (response.status === 200) {
                if (response.data.auth) {
                    if (response.data.otpAuthentication === 1) {
                        this.setState({
                            otpAuthentication: response.data.otpAuthentication,
                            otpMessage: "OTP has been sent to your Email",
                            userData: response.data,
                            successLogin: 0,
                        });
                    } else {
                        this.setState({
                            "successLogin": 1,
                        })
                        localStorage.setItem("email",response.data.user.email);
                        localStorage.setItem("token",response.data.token);
                        localStorage.setItem("user_type",response.data.user.user_type);
                        localStorage.setItem("role",response.data.user.role);
                        localStorage.setItem("user_name",response.data.user.username);
                        console.log("38")
                        //////////////////////////////////////////////////////////////////////
                        // localStorage.setItem('token', response.data.token);
                        localStorage.setItem('userid', response.data.userid); // Adding User Id For Save Grid and Reset Grid Layout Changes
                        // localStorage.setItem('dept_code', JSON.stringify(response.data.user.dept_code));
                        // localStorage.setItem('is_admin', response.data.user.is_admin);
                        // localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
                        // localStorage.setItem('is_global', response.data.user.is_global);
                        // localStorage.setItem('firstname', response.data.user.firstname);
                        // localStorage.setItem('username', response.data.user.username);
                        // localStorage.setItem('lastname', response.data.user.lastname);
                        // localStorage.setItem('pc', response.data.user.plant_code || "HZ");
                        // localStorage.setItem('email', response.data.user.email.replace("[at]", "@").replace("[dot]", "."));
                        // localStorage.setItem('user_type', response.data.user.user_type);
                        // localStorage.setItem('plant_code', response.data.user.plant_code || "");
                        // localStorage.setItem('roles', response.data.user.roles);
                        // localStorage.setItem('actual_roles', response.data.user.roles);
                        sessionStorage.setItem('ref', 1);
                        this.setState({
                            username: '',
                            email: '',
                            password: '',
                            loginMessage: response.data.msg
                        });
                    }
                } else {
                    this.setState({
                        username: '',
                        email: '',
                        password: '',
                        loginMessage: response.data.msg
                    });
                }
            } else {
                this.setState({
                    username: '',
                    email: '',
                    password: '',
                    loginMessage: response.data.msg
                });
            }
        } catch (error) {
            console.error("Error authenticating user:", error);
        }
    }
    getUserDashboardUrl(pathToMatch = null) {
        var dashboardUrl = "/exportoperationscontroltower"
        return dashboardUrl;
    }
    cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    render() {
        if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
            var successLogin = this.state.successLogin;
            console.log(successLogin,'100')
            if (successLogin == 1) {
                // let dashboardUrl = this.getUserDashboardUrl();
                // console.log(dashboardUrl, "dashboardUrl1143");
                return window.location.href = "/exportoperationscontroltower"; // Return null to prevent rendering anything else
            }
        }
        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper">
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Authenticating... <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}
            </div>
        );
    }
}
export default EnmUserLogin;