import React, { useState, useEffect, useRef, useCallback } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Datetime from 'react-datetime';
import moment from "moment";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import redirectURL from "../redirectURL";
import { getHyphenDDMMMYYYYHHMM } from "../utils";
const UserActivityReport = () => {
    let gridApi = useRef(null);
    let gridColumnApi = useRef(null)
    var gridRef = useRef();
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [updatedData, setUpdatedData] = useState([]);
    var [fromDate, setFromDate] = useState(moment(new Date()).format('DD-MM-YYYY'))
    var [toDate, setToDate] = useState(moment(new Date()).format('DD-MM-YYYY'))
    useEffect(() => {
        if (gridRef.current) {
        }
    }, [gridRef]);
    const handleCellValueChanged = (params) => {
        var updatedRowData = [...updatedData];
        updatedRowData.push(params.data)
        setUpdatedData(updatedRowData);
    };
    const onGridReady = useCallback((params) => {
        gridApi.current = params.api;
        gridColumnApi = params.columnApi
    }, []);
    const getRowStyle = (params) => {
        if (!params || !params.data || !params.data.report_date) {
            return null;
        }
        var currentWeekDates = getCurrentWeekDates();
        var reportDate = params.data.report_date;
        if (currentWeekDates.includes(reportDate)) {
            return { backgroundColor: '#FF7F7F' };
        }
        return null;
    };
    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        setloadshow('show-m')
        setoverly('show-m')
        var params = {
            from_date: fromDate,
            to_date: toDate
        }
        redirectURL.post('/consignments/fetchuserlogsdata', params).then((resp) => {
            var data = resp.data
            setrowData(data)
            setoriginalData(data)
            setloadshow('show-n')
            setoverly('show-n')
        })
    }
    const columnwithDef = [
        {
            headerName: "User Name",
            field: "username",
            width: 140,
            filter: true,
            resizable: true,
            // flex:1,
        },
        {
            headerName: "Email Id",
            field: "email",
            width: 140,
            filter: true,
            resizable: true,
            // flex:1,
        },
        {
            headerName: "User Role",
            field: "role",
            width: 100,
            filter: true,
            resizable: true,
            // flex:1,

        },
        {
            headerName: "Screen",
            field: "screen_name",
            width: 220,
            filter: true,
            resizable: true,
            // flex:1,
            valueGetter: params => {
                try {
                    let { screen_name } = params.data
                    if (screen_name) {
                        let result = formatString(screen_name)
                        return result
                    }
                    return ""
                } catch (e) {
                    console.log("Error at screen", e)
                }
            }
        },
        {
            headerName: "Action",
            field: "activity",
            width: 280,
            filter: true,
            resizable: true,
            // flex:1,
        },
        {
            headerName: "Action Performed On",
            field: "activity_date",
            width: 180,
            filter: true,
            resizable: true,
            // flex:1,
            valueGetter: params => {
                {
                    try {
                        let { activity_date } = params.data
                        if (activity_date) {
                            return getHyphenDDMMMYYYYHHMM(activity_date)
                        }
                    } catch (e) {
                        console.log("Error at ,activity_date ", e)
                    }
                }
            }
        },
        {
            headerName: "Action Items",
            field: "",
            minWidth: 340,
            maxWidth: 900,
            filter: true,
            resizable: true,
            valueGetter: params => {
                try {
                    let { work_order_no, work_order_no_new, container_no, truck_no } = params.data;
                    let result = "";
                    // Function to handle arrays and single values
                    const formatValue = (value) => {
                        if (Array.isArray(value)) {
                            return value.join(" , ");
                        } else if (value) {
                            return value;
                        }
                        return "";
                    };
                    if (work_order_no && !work_order_no_new) {
                        // Case when only work_order_no exists
                        result = formatValue(work_order_no);
                        return "Work Order No: " + result;
                    } else if (work_order_no_new && work_order_no) {
                        // Case when both work_order_no and work_order_no_new exist
                        let result2 = formatValue(work_order_no_new);
                        result = formatValue(work_order_no);
                        return "Work Order Transferred: " + result + " Work Order Received: " + result2;
                    } else if (container_no) {
                        // Case when container_no exists
                        result = formatValue(container_no);
                        return "Container No: " + result;
                    } else if (truck_no) {
                        // Case when truck_no exists
                        result = formatValue(truck_no);
                        return "Truck No: " + result;
                    }
                } catch (e) {
                    console.log("Error at valueGetter, work_order_no:", e);
                }
            }
        },
        {
            headerName: "Action Remarks",
            field: "activity_remarks",
            width: 140,
            filter: true,
            resizable: true,
            hide:true,
            // flex:1,
        },
        // {
        //     headerName: "Work Orders",
        //     field: "work_order_no",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        //     // flex:1,
        //     valueGetter: params => {
        //         {
        //             try {
        //                 let { work_order_no } = params.data
        //                 let result = ""
        //                 if(work_order_no && Array.isArray(work_order_no)){
        //                      result = work_order_no.join(" , ")
        //                 }else if(work_order_no){
        //                      result = work_order_no
        //                 }
        //                 return result 
        //             } catch (e) {
        //                 console.log("Error at ,work_order_no ", e)
        //             }
        //         }
        //     }
        // },
        // {
        //     headerName: "Containers",
        //     field: "container_no",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        //     // flex:1,
        //     valueGetter: params => {
        //         {
        //             try {
        //                 let { container_no } = params.data
        //                 let result = ""
        //                 if(container_no && Array.isArray(container_no)){
        //                      result = container_no.join(" , ")
        //                 }else if(container_no){
        //                      result = container_no
        //                 }
        //                 return result 
        //             } catch (e) {
        //                 console.log("Error at ,container_no ", e)
        //             }
        //         }
        //     }
        // },  
        // {
        //     headerName: "Trucks",
        //     field: "truck_no",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        //     // flex:1,
        //     valueGetter: params => {
        //         {
        //             try {
        //                 let { truck_no } = params.data
        //                 let result = ""
        //                 if(truck_no && Array.isArray(truck_no)){
        //                      result = truck_no.join(" , ")
        //                 }else if(truck_no){
        //                      result = truck_no
        //                 }
        //                 return result 
        //             } catch (e) {
        //                 console.log("Error at ,container_no ", e)
        //             }
        //         }
        //     }
        // },         
    ]
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const filterDatafordates = () => {
        var from_date = fromDate
        var to_date = toDate
        if (from_date == "Invalid date") {
            from_date = '';
        }
        if (to_date == 'Invalid date') {
            to_date = '';
        }

        if (from_date != '') {
            const dateObj = moment(from_date, 'DD-MM-YYYY');
            const formattedFromDate = dateObj.toDate();
            from_date = formattedFromDate;
        }
        if (to_date != '') {
            const dateObj = moment(to_date, 'DD-MM-YYYY');
            const formattedFromDate = dateObj.toDate();
            to_date = formattedFromDate;
        }
        if (from_date != '' && from_date != undefined && from_date != null && to_date != '' && to_date != undefined && to_date != null) {
            if (from_date <= to_date) {
                getData() // Fetching The Data
            }
            else {
                setbasicTitle('From Date sholud be less than To Date')
                setbasicType('danger')
                setoverly('show-n')
                setshow(true)
            }
        }
        else {
            setbasicTitle('Please Select Start Date and End date')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    const handleFromDate = (e) => {
        const date = moment(e).format('DD-MM-YYYY');
        setFromDate(date)
    };
    const handleToDate = (e) => {
        const date = moment(e).format('DD-MM-YYYY');
        setToDate(date)
    };
    const onClickHideAll = () => {
        setloadshow('show-n')
        setoverly('show-n')
    }
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="form-group d-flex justify-content-between">
                    <h1 className="heading mt-30p">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>User Activity
                    </h1>
                </div>
                <div className="card m-10p">
                    <div className="card-body" id="counterCardBody">
                        <div className="row">
                            <div className="col-sm-8 checkForDateFilter_gap">
                                <div className="row">

                                    <div className="form-group col-xl-4 col-lg-4">
                                        <div>
                                            <label> From Date </label>
                                            <Datetime
                                                key={fromDate}
                                                placeholder="Date"
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                name="from_date"
                                                inputProps={{ readOnly: 'true' }}
                                                value={fromDate}
                                                dateFormat="DD-MM-YYYY"
                                                id='from_date'
                                                timeFormat={false}
                                                onChange={(e) => handleFromDate(e)}
                                                required />
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <div>
                                            <label> To Date </label>
                                            <Datetime
                                                key={toDate}
                                                placeholder="Date"
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                name="to_date"
                                                inputProps={{ readOnly: 'true' }}
                                                value={toDate}
                                                dateFormat="DD-MM-YYYY"
                                                id='to_date'
                                                timeFormat={false}
                                                onChange={(e) => handleToDate(e)}
                                                required />
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <button className="btn btn-success mt-30p" onClick={filterDatafordates}>Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <div className="col textAlignRight">
                                        <a href={"/reportscreen"} className="f12 mr-5p btn btn-default">
                                            Back
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-sm-12" >
                                <div id="idgrid" style={{ width: "100%", height: "480px" }} className="ag-theme-balham ">
                                    <AgGridReact
                                        columnDefs={columnwithDef}
                                        defaultColDef={{
                                            sortable: true,
                                            filter: true,
                                            editable: false,
                                            resizable: true,
                                            menuTabs: ['filterMenuTab']
                                        }}
                                        headerHeight={70}
                                        // rowHeight={70}
                                        rowData={rowData}
                                        suppressRowClickSelection={true}
                                        frameworkComponents={{}}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        enableRangeSelection={true}
                                        paginationPageSize={100}
                                        rowSelection={'multiple'}
                                        masterDetail={true}
                                        pagination={true}
                                        sideBar={{
                                            toolPanels: [
                                                {
                                                    id: "columns",
                                                    labelDefault: "Columns",
                                                    labelKey: "columns",
                                                    iconKey: "columns",
                                                    toolPanel: "agColumnsToolPanel"
                                                },
                                                {
                                                    id: "filters",
                                                    labelDefault: "Filters",
                                                    labelKey: "filters",
                                                    iconKey: "filter",
                                                    toolPanel: "agFiltersToolPanel"
                                                }
                                            ]
                                        }}
                                        statusBar={{
                                            statusPanels: [
                                                { statusPanel: "agFilteredRowCountComponent" },
                                                { statusPanel: "agSelectedRowCountComponent" },
                                                { statusPanel: "agAggregationComponent" }
                                            ]
                                        }}
                                        gridOptions={{
                                            icons: {
                                                next: `<img src="${customNextIcon}" alt="Next" />`,
                                                previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                                first: `<img src="${customFirstIcon}" alt="First" />`,
                                                last: `<img src="${customLastIcon}" alt="Last" />`,
                                            },
                                        }}
                                        ref={gridRef}
                                        onCellEditingStopped={handleCellValueChanged}
                                        onGridReady={onGridReady}
                                        context={{}}
                                        getRowStyle={getRowStyle}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>
        </>
    )
}
function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
const getCurrentWeekDates = () => {
    let currentDate = moment(); // Get the current date
    let startOfWeek = currentDate.clone();
    let endOfWeek = moment().endOf('month').add(6, 'days');
    let dates = []; // Array to store the dates
    for (let date = startOfWeek; date <= endOfWeek; date = date.clone().add(1, 'days')) {
        dates.push(date.format('DD-MMM-YYYY'));
    }
    return dates;
};
function formatString(input) {
    // Step 1: Replace underscores with spaces
    const formatted = input.replace(/_/g, ' ');
    // Step 2: Capitalize the first letter of each word
    const result = formatted.replace(/\b\w/g, char => char.toUpperCase());
    return result;
}
export default UserActivityReport