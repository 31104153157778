import React, { useState, useEffect, useRef } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import Select from "react-select";
import Datepicker from "../layouts/datepicker";
import moment from "moment";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY } from '../common/utils';
import SearchIcon from "../../assets/icons/search-icon.svg";
import RefreshIcon from "../../assets/images/refresh-icon.svg";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import CountryFlagComponent from '../layouts/countryFlag';
import DestinationFlagComponent from '../layouts/destinationCountryFlag';
import ViewDOdetailsComponent from "../layouts/viewDOdetails";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import { fetchGrid } from "../fetchGridStructure"
import redirectURL from "../redirectURL";
const LspAccidentsData = () => {
    const gridApi = useRef(null);
    const gridColumnApi = useRef(null);

    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [rowData, setrowData] = useState([])
    var [selectedData, setselectedData] = useState([])
    var [filterdata, setfilterdata] = useState([])
    var [checkinput, setcheckinput] = useState(1)
    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [checkresetfilter, setcheckresetfilter] = useState(0)
    var [originalData, setoriginalData] = useState([])
    var [show, setshow] = useState(false)
    var [reload, setreload] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [overly, setoverly] = useState('show-n')
    var [loadshow, setloadshow] = useState('show-n')
    var typeofselection = [
        { label: 'Stuffing Date', value: 'stuffing_date' },
        { label: 'Plant', value: 'plant' },
        { label: 'CHA', value: 'cha' },
        { label: 'Ship to Country', value: 'ship_to_country' },
    ]
    var [showFilterForm, setShowFilterForm] = useState("");
    const [showAddForm, setshowAddForm] = useState('')
    // var [woReleaseDate, setWoReleaseDate] = useState({
    //     wo_release_from_date: '',
    //     wo_release_to_date: ''
    // })
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: moment().format('DD-MM-YYYY'),
        wo_release_to_date: moment().add(2, 'days').format('DD-MM-YYYY')
    });
    var [plant, setPlant] = useState({ 'label': 'All', 'value': 'All' });
    var [cha, setCha] = useState({ label: 'All', value: 'All' });
    var [shiptocountry, setShiptoCountry] = useState({ label: 'All', value: 'All' });
    var [plantList, setPlantList] = useState([]);
    var [chaList, setChaList] = useState([]);
    var [shipToCountryList, setShipToCountryList] = useState([]);
    var [truckList, settrucksList] = useState([])
    var [truck_no, settruck_no] = useState('')
    const [manualUploadDetails, setmanualUploadDetails] = useState({
        driver_name: '',
        driver_no: '',
        status: '',
        accident_place: '',
        accident_date: '',
        accident_comment: '',
        court_name: '',
        // court_filling_no: '',
        // court_hearing_date: '',
        advocate_name: '',
        advocate_no: '',
        fir_no: '',
        selectedWrno:''

    })
    var [accidentDate, setaccidentDate] = useState('')
    var [accidentPlace, setaccidentPlace] = useState('')

    var [accidentComments, setAccidentComments] = useState('')
    var [courtName, setCourtName] = useState('')
    var [courtFillingNumber, setCourtFillingNumber] = useState('')
    var [firNumber, setFirNumber] = useState('')
    var [courtHearingDate, setCourtHearingDate] = useState('')
    var [advocateName, setAdvocateName] = useState('')
    var [advocateNumber, setAdvocateNumber] = useState('')
    var [driverName, setDriverName] = useState('')
    var [driverNumber, setDriverNumber] = useState('')
    var [status, setStatus] = useState('')
    const defaulColumnDefs = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Truck No",
            field: "truck_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Accident Date",
            field: "accident_date",
            width: 150,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.accident_date != "" && params.data.accident_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.accident_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Accident Place",
            field: "accident_place",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Accident Comments",
            field: "accident_comment",
            width: 180,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Court Name",
        //     field: "court_name",
        //     width: 140,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Court Filling Number",
        //     field: "court_filling_no",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Fir Number",
        //     field: "fir_no",
        //     width: 140,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Court Hearing Date",
        //     field: "court_hearing_date",
        //     width: 140,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Advocate Name",
        //     field: "advocate_name",
        //     width: 140,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Advocate Number",
        //     field: "advocate_no",
        //     width: 140,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Driver Name",
        //     field: "driver_name",
        //     width: 140,
        //     filter: true,
        //     resizable: true,
        // },
        {
            headerName: "Driver Number",
            field: "driver_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Status",
            field: "status",
            width: 140,
            filter: true,
            resizable: true,
        },

    ]    
    var [summaryViewcols, setsummaryViewcols] = useState(defaulColumnDefs)
    useEffect(()=>{
        if (showAddForm ==='slide30'){
           document.body.style.overflow = 'hidden';
        }else{
           document.body.style.overflow = 'auto';
        }
        return ()=>{
           document.body.style.overflow = 'auto';
        }
      }, [showAddForm])
    // useEffect(() => {
    //     if (gridApi.current) {
    //         getUserGridState();
    //     }
    // }, [gridApi]);
    useEffect(() => {
        loadDateTimeScript();
        var trucks_list = []
        redirectURL.post('/consignments/getTrucks').then((resp) => {
            //console.log(resp.data, '188')
            var trucks = resp.data.trucksData
            trucks.map((e) => {
                trucks_list.push({ 'label': e.truck_no, 'value': e.truck_no })
            })
            settrucksList(trucks_list)

        })
        settrucksList()
        getdata()

    }, [])
    const onClickHide=()=>{
        setloadshow('show-n')
}
    const getdata = () => {
        setloadshow('show-m')
        setoverly('show-m')
        redirectURL.post('/consignments/getlspaccidentdata').then((resp) => {
            try {
                var records = resp.data.records;
                //console.log(records, '75');
                if (records != '' && records != undefined) {
                    var data = records
                    setoriginalData(data);
                    records = sortByCreatedDate(records);
                    var from_date = woReleaseDate.wo_release_from_date;
                    var to_date = woReleaseDate.wo_release_to_date;
                    if (from_date && to_date) {
                        from_date = moment(from_date, 'DD-MM-YYYY');
                        to_date = moment(to_date, 'DD-MM-YYYY');
                        //console.log(from_date, '271');
                        //console.log(to_date, '272');
                        var filteredData = data.filter(item => {
                            var stuffing = getHyphenDDMMYYYY(item.created_date)
                            var itemDate = moment(stuffing, 'DD-MM-YYYY')
                            return itemDate.isBetween(from_date, to_date, null, '[]');
                        });
                        //console.log(filteredData, 'filteredData');
                        setfilterdata(filteredData);
                        setrowData(filteredData);
                        setselectedData(filteredData)
                       
                    }
                }
                setloadshow('show-n')
                setoverly('show-n')

            } catch (e) {
                console.error('Error:', e);
            }
        });
    }
    const handleraccidentDateTime = (e) => {
        //console.log(e)
    }
    const onGridReady = params => {
        gridApi.current = params;
        gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
    const handleInputfilter = (e) => {
        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
    }
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: window.location.pathname ,userId:localStorage.getItem("userid") })
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols = fetchGrid(summaryViewcols, grid[0].gridcolumns)
                //console.log("oCols", oCols)

                // setsummaryViewcols(oCols);
            })
    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols = fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            //console.log(e);
        }
    };
    const onGridState = () => {
        ////console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */

        window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        // getUserGridState();


    }
    const handleFilterOptions = (e) => {
        if (e.value == 'stuffing_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        if (val != '' && val != undefined) {
            var filteredData
            if (typeoffield == 'ship_to_country') {
                filteredData = originalData.filter(item => item.ship_to_country.toLowerCase() == val.toLowerCase());
            }
            else if (typeoffield == 'plant') {
                filteredData = originalData.filter(item => item.plant.toLowerCase() == val.toLowerCase());
            }
            else {
                //console.log('96')
                filteredData = originalData.filter(item => item.cha.toLowerCase() == val.toLowerCase());
            }
            if (filteredData != '' && filteredData != undefined) {
                if (localStorage.getItem('role') == 'tpt') {
                    filteredData = filteredData.filter(k => k.transporter_name == localStorage.getItem('tpt_name'))
                }
            }
            setrowData(filteredData)
            setcheckresetfilter(1)
        }
        else {
            setbasicTitle('Please Enter Value')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    var onClickAddCase = () => {
        setshowAddForm("slide30")
        setoverly("show-m")
    }
    var onClickSaveGridState = () => {
        try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = window.location.pathname
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: window.location.pathname,
                screentitle: screenpage
            }
            //console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    //console.log("Error ", e)
                })
            //console.log('hit')
        }
        catch (e) {
            //console.log(e)
        }
    }
    const resetState = () => {
        try {
            // Reset columns to their initial state (order, visibility, width)
            setsummaryViewcols(defaulColumnDefs)
            const initialColumnState = defaulColumnDefs.map(col => ({
                colId: col.field,
                hide: col.hide || false,
                ...col
            }));
            // Apply the column state to reset order and visibility
            gridApi.current.columnApi.applyColumnState({
                state: initialColumnState,
                applyOrder: true  // This ensures that the column order is also reset
            });
            gridApi.current.api.refreshCells();
            // After the reset, you may want to fetch the user grid state from the backend
            const reqparams = {
                userId: localStorage.getItem("userid"),
                screenurl: window.location.pathname,
                screentitle: window.location.pathname,  // Adjust to your actual screen title
            };
            redirectURL.post("/consignments/removeUserGridState", reqparams)
                .then((response) => {
                    setshow(true);
                    setbasicTitle("Successfully reset default grid layout");
                    setbasicType("success");
                    // Optionally, reload grid state from the backend (if needed)
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000);
                })
                .catch((error) => {
                    setshow(true);
                    setbasicTitle("Error resetting grid layout");
                    setbasicType("error");
                    console.error('Error resetting grid state:', error);
                });
        } catch (error) {
            console.error('Unexpected error in resetState:', error);
        }
    };
    // const onClickSaveGridState = () => {
    //     try {
    //         if (gridApi.current) {
    //             const columnState = gridColumnApi.current.getColumnState();
    //             const columnGroupState = gridColumnApi.current.getColumnGroupState();
    //             const sortState = gridApi.current.api.getSortModel(); // Accessing getSortModel from gridApi.current.api
    //             const filterState = gridApi.current.api.getFilterModel();

    //             var screenpage = '/shipmentplanningreport';
    //             let reqparams = {
    //                 gridcolumns: columnState,
    //                 gridgroup: columnGroupState,
    //                 gridcolsort: sortState,
    //                 gridcolfilter: filterState,
    //                 userId: localStorage.getItem("userid"),
    //                 screenurl: window.location.pathname,
    //                 screentitle: screenpage
    //             };

    //             redirectURL.post("/consignments/saveGridStates", reqparams)
    //                 .then((response) => {
    //                     setshow(true);
    //                     setbasicTitle("Successfully saved grid layout");
    //                     setbasicType("success");
    //                     setTimeout(() => {
    //                         getUserGridState();
    //                     }, 2000);
    //                 })
    //                 .catch(function (e) {
    //                     //console.log("Error ", e);
    //                 });
    //         } else {
    //             console.error('Grid API is .');
    //         }
    //     } catch (e) {
    //         //console.log(e);
    //     }
    // };
    const filterDatafordates = () => {
        setloadshow('show-m')
        setoverly('show-m')
        var from_date = woReleaseDate.wo_release_from_date
        var to_date = woReleaseDate.wo_release_to_date
        //console.log(from_date, "formDate55")
        //console.log(to_date, "toDate55")
        //console.log(originalData, '129')
        if (from_date=="Invalid date"){
            from_date = '';
        }
        if (to_date =='Invalid date'){
            to_date = '';
        }
        
        if (from_date !=''){
            const dateObj = moment(from_date, 'DD-MM-YYYY');
            const formattedFromDate = dateObj.toDate();
            from_date= formattedFromDate;
        }
        if (to_date !=''){
            const dateObj = moment(to_date, 'DD-MM-YYYY');
            const formattedFromDate = dateObj.toDate();
            to_date= formattedFromDate;
        }
        //console.log((from_date), "fromDate555")
        //console.log(to_date, "toDate55")
        const valid = from_date<to_date
        //console.log(valid, "condition")
        if (from_date != '' && from_date != undefined && from_date != null && to_date != '' && to_date != undefined && to_date != null) {
            if (from_date <= to_date){
                from_date = moment(from_date, 'DD-MM-YYYY')
                to_date = moment(to_date, 'DD-MM-YYYY')
                var filteredData = originalData.filter(item => {
                    //console.log(item, "888")
                    var itemDate = getHyphenDDMMYYYY(item.created_date)
                    itemDate = moment(itemDate, 'DD-MM-YYYY')
                    //console.log(itemDate,'itemDate')
                    //console.log(from_date,'from_date')
                    //console.log(to_date,'to_date')
                    return itemDate.isBetween(from_date, to_date, null, '[]') || itemDate.isSame(from_date); //3073- It shows the data even if we are giving same from and to dates.
                });
                //console.log(filteredData, 'filteredData1')
                setTimeout(() => {
                    setrowData(filteredData)
                    setloadshow('show-n')
                    setoverly('show-n')
                }, 1000);
            }
            else{
                setbasicTitle('From Date sholud be less than To Date')
                setbasicType('danger')
                setoverly('show-n')
                setshow(true)
                setloadshow('show-n')
                setoverly('show-n')
            }
        }
        else {
            setbasicTitle('Please Select Start Date and End date')
            setbasicType('danger')
            setoverly('show-n')
            setloadshow('show-n')
            setshow(true)
        }
    }
    const handleDatesSelected = (e) => {
        //console.log(e, "8989")

        const date = moment(e).format('DD-MM-YYYY');

        //console.log(date, "datemoment")

        setWoReleaseDate(prev => ({
            ...prev,
            wo_release_from_date: date
        }))

    };
    const handleDatesSelected1 = (e) => {
        //console.log(e, "8989")
        const date = moment(e).format('DD-MM-YYYY');

        //console.log(date, "datemoment")
        setWoReleaseDate(prev => ({
            ...prev,
            wo_release_to_date: date
        }))
    };
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')
        settruck_no({'label':'','value':''})
        setmanualUploadDetails({
            driver_name: '',
            driver_no: '',
            accident_place: '',
            status: null,
            accident_comment: '',
            tentative_cut_off_date: '',
            court_name: '',
            // court_filling_no: '',
            advocate_name: '',
            advocate_no: '',
            status: '',
            fir_no: ''
        }
        )
    }
    //Bug no :3826 - fixing the issue of submit buttom
    const onsubmitdata = (e) => {
        e.preventDefault()
        setshowAddForm('')
        var accident_date = document.getElementById("accident_date").value;
        //console.log(accident_date, 'accident_date'); // Debugging line
      
    
      // Specify the format of the date string
    var dateFormat = "DD-MM-YYYY HH:mm";

    // Parse the date using moment.js and convert it to the desired timezone
    var parsedAccidentDate = moment(accident_date, dateFormat).utcOffset("+05:30")._d;
    //console.log(parsedAccidentDate, 'parsedAccidentDate'); // Debugging line

        var params = {
            'truck_no': truck_no.value,
            'driver_name': manualUploadDetails.driver_name,
            'driver_no': manualUploadDetails.driver_no,
            'status': manualUploadDetails.status,
            'accident_place': manualUploadDetails.accident_place,
            'accident_date': parsedAccidentDate,
            'accident_comment': manualUploadDetails.accident_comment,
            'court_name': manualUploadDetails.court_name,
            'court_filling_no': manualUploadDetails.court_filling_no,
            // 'court_hearing_date': document.getElementById("court_hearing_date").value,
            'advocate_name': manualUploadDetails.advocate_name,
            'advocate_no': manualUploadDetails.advocate_no,
            'fir_no': manualUploadDetails.fir_no,
            'created_date': moment.parseZone(new Date()).utcOffset("+05:30")._d,
            'work_order_no':manualUploadDetails.selectedWrno
        }
        //console.log(params, 'params')
        redirectURL.post('/consignments/insertaccidentdetails', params).then((resp) => {
            if (resp.data.message == 'Success') {
                setshow(true)
                setbasicTitle("Succsessfully Updated")
                setbasicType("success")
                setloadshow('show-n')
                setoverly('show-n')
                setshowAddForm('')
                setmanualUploadDetails({})              
                settruck_no({ label: '', value: '' })
                setmanualUploadDetails({
                    driver_name: '',
                    driver_no: '',
                    accident_place: '',
                    status: null,
                    accident_comment: '',
                    tentative_cut_off_date: '',
                    court_name: '',
                    // court_filling_no: '',
                    advocate_name: '',
                    advocate_no: '',
                    status: '',
                    fir_no: '',
                    work_order_no:''
                }
                )
                // $('#court_hearing_date').val('');
                $('#accident_date').val('');
              
                getdata()


            }
        })
    }
    const onClickDOdetails = (params) => {
        //console.log('163', params)
        //console.log(params.data.attachmentFileName)
        if (params.data.attachmentFileName != undefined && params.data.attachmentFileName != "") {
            var fileparams = {
                attachmentFileName: params.data.attachmentFileName,
                work_order_no: params.data.work_order_no
            }
            redirectURL.post("/consignments/downloadDOFileFile", fileparams, {
            }).then((response) => {
                const imageURL = response.data;

                window.open(imageURL, '_blank');
            }).catch(function (error) {
                //console.log(error);
            })
        }
    }
    const refreshData = () => {
        setcheckresetfilter(0)
        setrowData(originalData)
        setselectedval(prev => ({
            ...prev,
            inputval: ''
        }))
    }
    const columnDefs = summaryViewcols

    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const onClickFilters = () => {
        setShowFilterForm("slide30")
        // setoverly("show-n")
    }

    const hideSlideBlock = () => {

        // showUpdateForm:"show-n",
        setShowFilterForm("")
        // showAddForm: "show-n",
        // setoverly("show-n"),
        // setloadshow("show-n")
        // showInvoiceForm: "show-n"

    }
    // const onChangeHandler=(e)=>{
    //     if (e.value=="plant"){
    //         setPlant(e);
    //     }else if (e.value=="cha"){
    //         setCha(e);
    //     }else if (e.value=="shiptocountry"){
    //         setShiptoCountry(e);
    //     }

    // }


    const filtersData = (e) => {
        e.preventDefault();
        setShowFilterForm('')
        var data = selectedData
        //console.log(data, '723')
        if (plant.value != '' && plant.value != undefined && plant.value != 'All') {
            data = data.filter(f => f.plant == plant.value)
        }
        else {
            data = data
        }
        //console.log(cha.value, '723')
        if (cha.value != '' && cha.value != undefined && cha.value != 'All') {
            data = data.filter(f => f.cha == cha.value)
        } else {
            data = data
        }
        //console.log(data, '723')

        if (shiptocountry.value != '' && shiptocountry.value != undefined && shiptocountry.value != 'All') {
            data = data.filter(f => f.ship_to_country == shiptocountry.value)
        } else {
            data = data
        }
        //console.log(data, '723')
        if (data != '' && data != undefined) {
            if (localStorage.getItem('role') == 'tpt') {
                data = data.filter(k => k.transporter_name == localStorage.getItem('tpt_name'))
            }
        }
        setrowData(data);
        // setPlant({'label':'','value':''})
        // setCha({'label':'','value':''})
        // setShiptoCountry({'label':'','value':''})
        // setPlant("");
        // setCha("");
        // setShiptoCountry("");

        // const filtersDataForm = {
        //     "Plant":plant,
        //     "CHA":cha,
        //     "ShiptoCountry":shiptocountry
        // }
        // //console.log(filtersDataForm);
        // var filteredData = originalData.filter((item)=>{
        //     return item.plant==plant || item.cha==cha || item.ship_to_country==shiptocountry});
        // setrowData(filteredData);

    }
    const restrictToOther = (e) => {
        e.target.value = e.target.value.replace()
    }
    const restrictToAlphabets = (event) => {
        const input = event.target.value;
        let inputName = event.target.name;
        const regex = /^[a-zA-Z\s]*$/; // Regex to allow only letters and spaces
        //console.log(event, "999")
        if (regex.test(input)) {
            if (inputName=='driverName'){
                setmanualUploadDetails(prev => ({ ...prev, driver_name: input }));
            }
            if (inputName=='status'){
                setmanualUploadDetails(prev => ({ ...prev, status: input }));
            }
            if (inputName=='accidentPlace'){
                setmanualUploadDetails(prev => ({ ...prev, accident_place: input }));
            }
            if (inputName=='accidentComments'){
                setmanualUploadDetails(prev => ({ ...prev, accident_comment: input }));
            }
            if (inputName=='courtName'){
                setmanualUploadDetails(prev => ({ ...prev, court_name: input }));
            }
            if (inputName=='advocateName'){
                setmanualUploadDetails(prev => ({ ...prev, advocate_name: input }));
            }
            


            
        }
    };
    const handleonchangetruckno = (e)=>{
        settruck_no(e)
        var params = {
            'truck_no':e.value
        }
        redirectURL.post('/consignments/getdrivermobileno', params)
    .then((resp) => {
      const record = resp.data;
      //console.log(record, "sobha");

      if (record && record.length > 0) {
        // If record is defined and has at least one element
        setmanualUploadDetails(prev => ({
          ...prev,
          driver_no: record[0].driver_mobile_no,
          selectedWrno: record[0].work_order_no
        }));
      } else {
        // Handle the case where no data is returned
        setmanualUploadDetails(prev => ({
          ...prev,
          driver_no: '', // or any default value you prefer
          selectedWrno: '' // or any default value you prefer
        }));
      }
    })
    .catch((error) => {
      console.error('Error fetching driver mobile number:', error);
      // Handle error case here, if needed
    });
    }
    const restrictToNumbers = (event) => {
        let input = event.target.value;
        let inputName = event.target.name;
        // Remove non-numeric characters
        input = input.replace(/\D/g, '');

        // Limit to 10 digits
        if (inputName== "driverNumber"){
            if (input.length > 10) {
                input = input.slice(0, 10);
            }
    
            setmanualUploadDetails(prev => ({ ...prev, driver_no: input }));
        }
        // if (inputName=="courtFillingNumber") {
        //     setmanualUploadDetails(prev => ({ ...prev, court_filling_no: input }));
        // }
        if (inputName=="firNumber") {
            setmanualUploadDetails(prev => ({ ...prev, fir_no: input }));
        }
        if (inputName=="advocateNumber") {
            if (input.length > 10) {
                input = input.slice(0, 10);
            }
            setmanualUploadDetails(prev => ({ ...prev, advocate_no: input }));
        }
        
       
    };

    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="form-group d-flex justify-content-between">
                    <h1 className="heading mt-30p">
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>LSP Accident Report
                    </h1>
                </div>
                <div className="card m-10p">
                    <div className="card-body" id="counterCardBody">
                        <div className="row">
                            <div className="col-sm-8 checkForDateFilter_gap">
                                <div className="row">

                                    <div className="form-group col-xl-4 col-lg-4">
                                        <div>
                                            <label> From Date </label>
                                            <Datetime
                                                key={woReleaseDate.wo_release_from_date}
                                                // onKeyDown ={(e)=> restrictToOther(e)}
                                                placeholder="Date"
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                name="from_date"
                                                inputProps={{ readOnly: 'true' }}
                                                value={woReleaseDate.wo_release_from_date}
                                                dateFormat="DD-MM-YYYY"
                                                id='from_date'
                                                timeFormat={false}

                                                onChange={(e) => handleDatesSelected(e)}
                                                required />
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <div>
                                            <label> To Date </label>
                                            <Datetime
                                                key={woReleaseDate.wo_release_to_date}
                                                placeholder="Date"
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                name="to_date"
                                                inputProps={{ readOnly: 'true' }}
                                                value={woReleaseDate.wo_release_to_date}
                                                dateFormat="DD-MM-YYYY"
                                                id='to_date'
                                                timeFormat={false}
                                                onChange={(e) => handleDatesSelected1(e)}
                                                required />
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <button className="btn btn-success mt-30p" onClick={filterDatafordates}>Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="row">
                                    <div className="col textAlignRight">
                                        <a href={"/reportscreen"} className="f12 mr-5p btn btn-default">
                                            Back
                                        </a>
                                        <button type="button" className="f12 mr-5p btn btn-outline-primary" onClick={onClickAddCase}>
                                            <img style={{ height: "10px", marginRight: '5px' }} />Add Case
                                        </button>
                                        <button
                                            type="button"
                                            title="Save Grid Layout"
                                            name="savegrid"
                                            className="btn btn-danger action_btn"
                                            style={{ height: "32px", marginTop: "30px", paddingBottom: "30px" }}
                                            onClick={onClickSaveGridState}>
                                            <i className="f12 icofont icofont-save"></i>
                                        </button>
                                        <button
                                            style={{ height: "32px", marginTop: "30px", paddingBottom: "30px", marginLeft: "4px" }}
                                            title="Reset Default Layout"
                                            className="float-right btn white btn-info" onClick={resetState}>
                                            <i className="icofont icofont-refresh"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-sm-12" >
                                <div id="idgrid" style={{ width: "100%", height: "450px" }} className="ag-theme-balham">
                                    <AgGridReact
                                        ref={gridApi}
                                        // modules={AllModules}
                                        columnDefs={columnDefs}
                                        defaultColDef={{
                                            sortable: true,
                                            filter: true,
                                            editable: false,
                                            resizable: true,
                                            menuTabs: ['filterMenuTab']
                                        }}
                                        rowData={rowData}
                                        suppressRowClickSelection={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        enableRangeSelection={true}
                                        paginationPageSize={100}
                                        onGridReady={onGridReady}
                                        onGridState={onGridState}
                                        rowSelection={'multiple'}
                                        masterDetail={true}
                                        pagination={true}
                                        frameworkComponents={{ CountryFlagComponent: CountryFlagComponent, DestinationFlagComponent: DestinationFlagComponent }}
                                        sideBar={{
                                            toolPanels: [
                                                {
                                                    id: "columns",
                                                    labelDefault: "Columns",
                                                    labelKey: "columns",
                                                    iconKey: "columns",
                                                    toolPanel: "agColumnsToolPanel"
                                                },
                                                {
                                                    id: "filters",
                                                    labelDefault: "Filters",
                                                    labelKey: "filters",
                                                    iconKey: "filter",
                                                    toolPanel: "agFiltersToolPanel"
                                                }
                                            ]
                                        }}
                                        statusBar={{
                                            statusPanels: [
                                                { statusPanel: "agFilteredRowCountComponent" },
                                                { statusPanel: "agSelectedRowCountComponent" },
                                                { statusPanel: "agAggregationComponent" }
                                            ]
                                        }}
                                        gridOptions={{
                                            icons: {
                                                next: `<img src="${customNextIcon}" alt="Next" />`,
                                                previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                                first: `<img src="${customFirstIcon}" alt="First" />`,
                                                last: `<img src="${customLastIcon}" alt="Last" />`,
                                            },
                                        }}
                                        context={{ onClickDOdetails }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"sliderBlock2 " + showAddForm} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Register a Court Case</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                {/* <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} > */}
                <div className="row">
                    <div className="col-sm-12">

                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className='row p-20p'>
                                <div className="form-group col-xl-12 col-lg-12">
                                        <p className='mb-5p'><span >Work Order No: </span> {manualUploadDetails.selectedWrno}</p>
                                       
                                    </div>
                                    {/* <div className="form-group col-xl-12 col-lg-12">
                                        <label className="">Truck No 
                                        </label>
                                        <Select placeholder="Select"
                                            value={truck_no}
                                            onChange={(e) => settruck_no(e)}
                                            options={truckList}
                                            required />
                                    </div> */}
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Truck No<span className="err-txt">*</span></label>
                                         <Select
                                            closeMenuOnSelect={true}
                                            value={truck_no}
                                            onChange={(e)=>handleonchangetruckno(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={truckList}
                                        />
                                        
                                    </div>
                                    {/* <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Driver Name
                                        {/* <span style={{ color: "#FF0000" }}>*</span> */}
                                        {/* </label>
                                        <input
                                            type="text"
                                            value={manualUploadDetails.driver_name}
                                            name="driverName"
                                            id="inputcode"
                                            onChange = {restrictToAlphabets}
                                            className="form-control"
                                            // onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, driver_name: e.target.value }))}
                                        />
                                    </div> */}
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Driver Number
                                        {/* <span style={{ color: "#FF0000" }}>*</span> */}
                                        </label>
                                        <input
                                            type="text"
                                            value={manualUploadDetails.driver_no}
                                            name="driverNumber"
                                            id="inputcode"
                                            // onChange={restrictToNumbers}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Status
                                        {/* <span style={{ color: "#FF0000" }}>*</span> */}
                                        </label>
                                        <input
                                            type="text"
                                            value={manualUploadDetails.status}
                                            name="status"
                                            id="inputcode"
                                            onChange = {restrictToAlphabets}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label>Accident Date 
                                            {/* <span style={{ color: "#FF0000" }}>*</span> */}
                                            </label>
                                        <input type="text" name="accident_date" autoComplete="off" className="form-control transhipment-datetime datetimepicker_mask" id="accident_date" />

                                        {/* <Datetime value={manualUploadDetails.accident_date}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{  name: 'accidentDate', autoComplete: 'off', readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        name="accidentDate"
                                        onChange={(e)=>handleraccidentDateTime(e)} /> */}
                                    </div>
                                    {/* <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Court Name
                                        <span style={{ color: "#FF0000" }}>*</span>
                                        </label>
                                        <input
                                            tyep="text"
                                            value={manualUploadDetails.court_name}
                                            name="courtName"
                                            id="inputcode"
                                            className="form-control"
                                            onChange = {restrictToAlphabets}

                                        />
                                    </div>  */}
                                      {/* <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Court Filling Number
                                        <span style={{ color: "#FF0000" }}>*</span> 
                                        </label>
                                        <input
                                            type="text"
                                            value={manualUploadDetails.court_filling_no}

                                            name="courtFillingNumber"
                                            id="inputcode"
                                            onChange={restrictToNumbers}

                                            className="form-control"
                                        />
                                    </div> */}
                                      {/* <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Fir Number
                                        <span style={{ color: "#FF0000" }}>*</span>
                                        </label>
                                        <input type="text"
                                            value={manualUploadDetails.fir_no}
                                            name="firNumber"
                                            id="inputcode"
                                            onChange={restrictToNumbers}
                                            className="form-control"
                                        />
                                    </div>  */}
                                    
                                    {/* <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Court Hearing Date
                                         <span style={{ color: "#FF0000" }}>*</span>
                                         </label>
                                        <input type="text" name="court_hearing_date" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="court_hearing_date" />

                                        <Datetime value={manualUploadDetails.court_hearing_date}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{name: 'courtHearingDate', autoComplete: 'off', readOnly: true }}
                                        dateFormat="YYYY-MM-DD"
                                        name="courtHearingDate"
                                     onChange={(e)=>setmanualUploadDetails(prev=>({...prev,court_hearing_date:e}))} 
                                    /> 
                                    </div>  */}
                                     {/* <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Advocate Name
                                        <span style={{ color: "#FF0000" }}>*</span> 
                                        </label>
                                        <input type="text"
                                            value={manualUploadDetails.advocate_name}
                                            name="advocateName"
                                            id="inputcode"
                                            onChange = {restrictToAlphabets}
                                            className="form-control"
                                        />
                                    </div>  */}
                                     {/* <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Advocate Number
                                        <span style={{ color: "#FF0000" }}>*</span> 
                                        </label> 
                                        <input
                                            type="text"
                                            value={manualUploadDetails.advocate_no}
                                            name="advocateNumber"
                                            id="inputcode"
                                            onChange={restrictToNumbers}
                                            className="form-control"
                                        />
                                    </div>   */}
                                       {/* <div className="form-group col-xl-12 col-lg-12">
                                                    <label className="">Acident Place <span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="accidentPlace" value={this.state.accidentPlace} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className="">Accident Comments <span style={{ color: "#FF0000" }}>*</span></label>
                                                    <textarea name="accidentComments" value={this.state.accidentComments} onChange={this.changeHandler} rows="4" className="form-control" ></textarea>
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className="">Court Name <span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="court_complex" value={this.state.court_complex} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className="">Case Number<span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="court_case_number" value={this.state.court_case_number} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className="">Court Filling Number<span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="court_filing_number" value={this.state.court_filing_number} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className="">Fir Number<span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="fir_number" value={this.state.fir_number} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className="">Advocate Name<span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="advocate_name" value={this.state.advocate_name} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className="">Advocate Number<span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="advocate_number" value={this.state.advocate_number} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className=" form-group col-xl-12 col-lg-12">
                                                    <label>Court Hearing Date </label>
                                                    <Datetime value={this.state.courtHearingDate}
                                                        disableCloseOnClickOutside={false}
                                                        closeOnSelect={true}
                                                        inputProps={{ placeholder: 'courtHearingDate', name: 'courtHearingDate', autoComplete: 'off', readOnly: true }}
                                                        style={{ width: '314px' }}
                                                        dateFormat="YYYY-MM-DD"
                                                        name="courtHearingDate"
                                                        onChange={this.handlercourtHearingDateTime} />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className="">Driver Name <span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="driverName" value={this.state.driverName} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className="">Driver Number<span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="driverNumber" value={this.state.driverNumber} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group col-xl-12 col-lg-12">
                                                    <label className="">Status <span style={{ color: "#FF0000" }}>*</span></label>
                                                    <Select placeholder="Select"
                                                        value={this.state.status}
                                                        onChange={(e)=> this.setState({status : e}) }
                                                        options={ [
                                                                {'value': 'new', 'label' :'New'},
                                                                {'value': 'in_progress', 'label' :'In Progress'},
                                                                {'value': 'closed', 'label' :'Closed'}
                                                            ]}
                                                        required />
                                                </div>  */}
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Accident Place
                                        {/* <span className="err-txt">*</span> */}
                                        </label>
                                        <input
                                            type="text"
                                            value={manualUploadDetails.accident_place}
                                            name="accidentPlace"
                                            // placeholder='Enter Partner Type'
                                            onChange = {restrictToAlphabets}
                                            id="inputcode"
                                            className="form-control"

                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Accident Comments 
                                        {/* <span style={{ color: "#FF0000" }}>*</span> */}
                                        </label>
                                        <input
                                            type="text"
                                            value={manualUploadDetails.accident_comment}

                                            name="accidentComments"
                                            id="inputcode"
                                            onChange = {restrictToAlphabets}
                                            className="form-control"
                                        />
                                    </div>
                                    
                                
                            <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/* </div>
                </div> */}
                {/* <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <div className="form-group col-sm-12 mb-20p text-center">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> */}


            </div>
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHide}></div>
       


        </>

    )
}

function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
        if (arr.length > 0) {
            const comparison = (a, b) => {
                const dateA = new Date(a.created_date);
                const dateB = new Date(b.created_date);
                if (descending) {
                    return dateB - dateA; // Ascending order
                } else {
                    return dateA - dateB; // Descending order
                }
            };
            arr.sort(comparison);
            return arr;
        }
    } else {
        return arr;
    }
}
function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}
function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        // mask:'39-19-9999',
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


export default LspAccidentsData;