import React from 'react';

const ReportScreen = () => {
    const getScreen = (e) => {
        const screens = {
            '1': '/shipmentplanningreport',
            '2': '/shipmentexecutionreport',
            '3': '/truckavailabilityreport',
            '4': '/trucksgps',
            '5': '/daywisetracking',
            '6': '/bufferreport',
            '7': '/transportertat',
            '8': '/planttat',
            '9': '/trucktrackingreport',
            '10': '/askingratereport',
            '11': '/trucksgpsintegration',
            '12':'/ontimePerformanceReport',
            '13':'/truckwithgeofence',
            '14':'/uptimegpsdata',
            '15':'/transportertargetvsachievment',
            '16':'/lspAccidentsData',
            '17':'/emergencyalerts',
            '18':'/userActivity',
        };
        window.location.href = screens[e];
    };
    if(localStorage.getItem('role')=='plant'){
        var reports = [
            { id: '1', title: 'Shipment Planning Report', image: require("../../assets/images/Shipmentplanningreport.png") },
            { id: '2', title: 'Shipment Execution Report', image: require("../../assets/images/Shipmentexecutionreport.png") },
            { id: '4', title: 'Trucks GPS', image: require("../../assets/images/TruckGPSData.png") },
            { id: '5', title: 'Day Wise Tracking', image: require("../../assets/images/DaywiseTrackingReport.png") },
            { id: '8', title: 'Plant TAT', image: require("../../assets/images/PlantAvgTATReport.png") },
            { id: '9', title: 'Truck Tracking Report', image: require("../../assets/images/TruckTrackingReport.png") },
            { id: '10', title: 'Asking Rate Report', image: require("../../assets/images/AskingRateReport.png") },
        ];
    }
    else if(localStorage.getItem('role')=='cha'){
        var reports = [
            { id: '1', title: 'Shipment Planning Report', image: require("../../assets/images/Shipmentplanningreport.png") },
            { id: '2', title: 'Shipment Execution Report', image: require("../../assets/images/Shipmentexecutionreport.png") },
            { id: '4', title: 'Trucks GPS', image: require("../../assets/images/TruckGPSData.png") },
            { id: '5', title: 'Day Wise Tracking', image: require("../../assets/images/DaywiseTrackingReport.png") },
            { id: '6', title: 'Buffer Report', image: require("../../assets/images/BufferReport.png") },
            { id: '8', title: 'Plant TAT', image: require("../../assets/images/PlantAvgTATReport.png") },
            { id: '9', title: 'Truck Tracking Report', image: require("../../assets/images/TruckTrackingReport.png") },
            { id: '12', title: 'On Time Performance', image: require("../../assets/images/OnTimePerf.png") },

        ];
    }
    else if(localStorage.getItem('role')=='tpt'){
        var reports = [
            { id: '2', title: 'Shipment Execution Report', image: require("../../assets/images/Shipmentexecutionreport.png") },
            { id: '3', title: 'Truck Availability Report', image: require("../../assets/images/Truckavailabilityreport.png") },
            { id: '4', title: 'Trucks GPS', image: require("../../assets/images/TruckGPSData.png") },
            { id: '7', title: 'Transporter TAT', image: require("../../assets/images/TransporterTATReport.png") },
            { id: '8', title: 'Plant TAT', image: require("../../assets/images/PlantAvgTATReport.png") },
            { id: '9', title: 'Truck Tracking Report', image: require("../../assets/images/TruckTrackingReport.png") },
            { id: '11', title: 'Trucks GPS Integration', image: require("../../assets/images/GPS.png") },
            { id: '12', title: 'On Time Performance', image: require("../../assets/images/OnTimePerf.png") },
            { id: '13', title: 'Truck Within Geofence', image: require("../../assets/images/TrucksWithinGeofence.png") },
        ];
    }
    else{
        var reports = [
            { id: '1', title: 'Shipment Planning Report', image: require("../../assets/images/Shipmentplanningreport.png") },
            { id: '2', title: 'Shipment Execution Report', image: require("../../assets/images/Shipmentexecutionreport.png") },
            { id: '3', title: 'Truck Availability Report', image: require("../../assets/images/Truckavailabilityreport.png") },
            { id: '4', title: 'Trucks GPS', image: require("../../assets/images/TruckGPSData.png") },
            { id: '5', title: 'Day Wise Tracking', image: require("../../assets/images/DaywiseTrackingReport.png") },
            { id: '6', title: 'Buffer Report', image: require("../../assets/images/BufferReport.png") },
            { id: '7', title: 'Transporter TAT', image: require("../../assets/images/TransporterTATReport.png") },
            { id: '8', title: 'Plant TAT', image: require("../../assets/images/PlantAvgTATReport.png") },
            { id: '9', title: 'Truck Tracking Report', image: require("../../assets/images/TruckTrackingReport.png") },
            { id: '11', title: 'Trucks GPS Integration', image: require("../../assets/images/GPS.png") },
            { id: '12', title: 'On Time Performance', image: require("../../assets/images/OnTimePerf.png") },
            { id: '10', title: 'Asking Rate Report', image: require("../../assets/images/AskingRateReport.png") },
            { id: '13', title: 'Truck Within Geofence', image: require("../../assets/images/TrucksWithinGeofence.png") },
            { id: '14', title: 'GPS Uptime', image: require("../../assets/images/GPSUptimeReport.png") },
            { id: '15', title: 'Target VS Achievment', image: require("../../assets/images/TargetVsAcheivment.png") },
            { id: '16', title: 'LSP Accidents', image: require("../../assets/images/emergencyalertdefault.png") },
            { id: '17', title: 'Emergency Alerts', image: require("../../assets/icons/accident_icon.png") },
            {id: '18',title:"User Activity",image: require("../../assets/icons/member-list.png") }
        ];
    }
    return (
        <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
            <div>
                <h1 className='heading mt-25p mb-15p'>Reports</h1>
            </div>
            <div className="card m-10p">
                <div className="card-body" id="counterCardBody">
                    <div className="crm-numbers pb-0">
                        <div className="row">
                            {reports.map(report => (
                                <div key={report.id} className="col-2 cursorPointer report-block"  onClick={() => getScreen(report.id)}>
                                    <h4 className="f13 heading" style={{ color: "#0664AE" }}>{report.title}</h4>
                                    <img src={report.image} alt="Icon" className='report-ico' />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportScreen;
