import React, { useState, useEffect, useRef, useReducer } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import { getHyphenDDMMYYYYHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY } from '../common/utils';
import Select from "react-select";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CountryFlagComponent from "./countryFlag";
import UpdatetrucksBtn from "./updateTrucksBtn";
import AcknowledgeBtnComponent from "./acknowledgeBtn";
import EdittruckComponent from "./edittrucksbtn";
import ConsignmentTrucksLegs from "./containertruckslegs";
import Mapcard2 from "./drawmap";
import ViewDOdetailsComponent from "./viewDOdetails";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import {fetchGrid} from "../fetchGridStructure"
import redirectURL from "../redirectURL";
import CustomSelect from "./customoption";

var initialState = {
    detailCellRendererParams: {},
};

var reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DETAIL_RENDERER_PARAMS':
            //console.log('2020')
            return {
                ...state,
                detailCellRendererParams: action.payload,
            };
        // case 'SET_DETAIL_RENDERER_PARAMS_FOR_STOPPAGES':
        // 	//console.log('6767')
        // 	return {
        // 		...state,
        // 		detailCellRendererParams: action.payload,
        // 	};
        default:
            return state;
    }
};
const Updatetrucksdetails = (props) => {
    const gridApi = useRef(null);
    const gridColumnApi = useRef(null);
    var [state, dispatch] = useReducer(reducer, initialState);
    var [rowData, setrowdata] = useState([])
    var [originalData, setoriginalData] = useState([])
    // var [gridApi, setGridApi] = useState(null);
    // var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [showAddForm, setshowAddForm] = useState('')
    var [showAddForm1, setshowAddForm1] = useState('')
    var [showAddForm4, setshowAddForm4] = useState('')
    var [selectedconfirm,setselectedconfirm] = useState(false)
    var checkfortruck = 0
    var [checkfordisableconfirm, setcheckfordisableconfirm] = useState(0)
    var selectconfirmn = [
        { label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }
    ]
    var [newworkordercheck, setnewworkordercheck] = useState(false);
    var [checkforsubmit, setcheckforsubmit] = useState(false);


    var [loadshow, setloadshow] = useState('show-n')
    var [loadData , setLoadData] = useState(false)
    var [overly, setoverly] = useState('show-n')
    var [trucksList, settrucksList] = useState([])
    var [assignedtruckslist, setassignedtrucksList] = useState([])
    var [manualUploadDetails, setmanualUploadDetails] = useState({
        trucks: [],
        wrno: '',
        confirm: ''
    })
    var [manualUploadDetails1, setmanualUploadDetails1] = useState({
        work_order_no: "",
        new_work_order_no: "",
        truck_no: [],
      });
    var [ files1, setFile1] = useState({
        file1 : '',
        file2 :[],
    })
    var [wrdo, setwrdo]=useState();
    const [showAddForm3, setshowAddForm3] = useState('')
    var [reassignbtnenable, setreassignbtnenable] = useState(false);
    var [truckList, settruckList] = useState([]);
    var [selectedWorkOrder, setSelectedWorkOrder] = useState({});
    var [wrlists1, setwrlist1] = useState([]);
    var [wrlists, setwrlist] = useState([]);
    var [manualUploadDetails2, setmanualUploadDetails2] = useState({
        work_order_no: "",
        new_work_order_no: "",
        container_no: [],
      });

    var [newtruck, setnewtruck] = useState([])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [workordernoList, setworkOrderList] = useState([])
    var [selected_row, setselected_row] = useState({})
    var [tabDatafortrucks, settabDatafortrucks] = useState({
        trucks_new: [],
        trucks_planned: [],
        trucks_modified: []
    })
    var [classForTab, setclassforTab] = useState({
        planned: 0,
        new: 1,
        modified: 0,
    })
    var [stateForMap, setStateForMap] = useState({
        sliderRouteTranslate: '',
        showDiv: 'show-n',
        mapinfo: {
            coords: [
                // { lat: 8.76735, lng: 78.13425 },
                // { lat: 18.76735, lng: 88.13425 },
            ],
        },
        mapdata:[],
        dealer: '',
        consignment_code: '',
        truck_no: '',
        container_no: '',
        maptruckno: '',
        routeTruck: '',
        sidebarSubHeader: '',
        rownode: '',
        consigner_coordinates: '',
        consignee_coordinates: '',
        map_type_of_tracking: '',
        baseRouteCoords: '',
        mapHeight: "94vh",
    })    
    var [downloadFile1Name,setDownLoadFile1Name] = useState("")
    var [selectedtrucksforeditlist,setselectedtrucksforeditlist] = useState([])
    const defaulColumnDefs = [
        
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    } else {
                        return "Not Available";
                    }
                } catch (e) {
                    // Handle the exception if needed
                }
            },
            comparator: dateComparator1,
            
        },
        // {
        //     headerName: "Tentative Vessel ETD",
        //     field: "tentative_vessel_etd",
        //     width: 170,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_vessel_etd != "" && params.data.tentative_vessel_etd != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
            comparator: dateComparator1,
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 130,
            filter: true,
            resizable: true,
            // cellRenderer: CountryFlagComponent
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Pendency",
            field: "remaining_trucks",
            width: 140,
            filter: true,
            resizable: true,
            hide:true,
            valueGetter:function(params){
                if(params.data.remaining_trucks!='' && params.data.remaining_trucks!=undefined){
                    return params.data.remaining_trucks
                }
                else{
                    return params.data.remaining_containers
                }
            },
            comparator: dateComparator1,
        },

        {
            headerName: "POD",
            field: "pod",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Booking No",
            field: "booking_no",
            width: 160,
            filter: true,
            resizable: true,
        },

        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Rejected By",
            field: "rejected_tpt",
            width: 200,
            resizable: true,
            // valueGetter:function(params){
            //     if(params.data.tpt_rejected==1){
            //         return params.data.rejected_tpt
            //     }
            //     else{
            //         return ""
            //     }
            // }
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "third_party_inspection_req",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Port Of Loading",
            field: "port_of_loading",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type Description",
            field: "vin_type_description",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Gate Open Date",
        //     field: "gate_open_date",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.gate_open_date != "" && params.data.gate_open_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.gate_open_date);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },

    ]
    const [summaryViewcols, setsummaryViewcols] = useState(defaulColumnDefs)
    useEffect(()=>{
        if (showAddForm ==='slide30'){
           document.body.style.overflow = 'hidden';
        }else{
           document.body.style.overflow = 'auto';
        }
        return ()=>{
           document.body.style.overflow = 'auto';
        }
      }, [showAddForm])
    useEffect(() => {
        if (gridApi.current) {
            getUserGridState();
        }
         loadDateTimeScript();
    }, [gridApi]);
    

    useEffect(() => {
        // setloadshow("show-m");
        if (!loadData) {
            getTptUpdTruckData()
            try {
                redirectURL.post("/consignments/getwolist").then((resp) => {
                    console.log(resp, "ZZZZZZZZZZZZZZZZZ")
                    var records = resp.data.trucks_records;
                    var wrlist = [];
                    records.map((e) => {
                        wrlist.push({ label: e.work_order_no, value: e.work_order_no });
                    });
                    setwrlist(wrlist);
                    //   var wrlist2 = [];
                    //   records1.map((e) => {
                    //       wrlist2.push({ label: e.work_order_no, value: e.work_order_no });
                    //     });
                    //   setwrlist2(wrlist2)  
                    // setloadshow("show-n");
                });
            } catch (error) {
                console.log(error);
                // Log the error
            } finally {
                //   setloadshow("show-n");
                // Set load state to 'show-n' after API call is done (either success or error)
            }
        }       
    }, [props.data])
    const getTptUpdTruckData = async () => {
        setloadshow("show-m");
        setLoadData(true)
        const typeOfUser = localStorage.getItem("user_type");
        const params = {};
        if (typeOfUser === "transporter") {
            params.transporter_code = localStorage.getItem("plant_code") || "";
        }
        await redirectURL.post('/consignments/getUpdateTruckDetailsData', params).then((resp) => { // Optimized api for Plant Data
            var records = resp.data
            var new_trucks = records.tptNew
            var planned_trucks = records.tptPlanned
            var modified_trucks = records.tptModified
            settabDatafortrucks({
                trucks_new: sortByCreatedDate(new_trucks),
                trucks_planned: sortByCreatedDate(planned_trucks),
                trucks_modified: sortByCreatedDate(modified_trucks)
            })
            sortByCreatedDate(new_trucks)
            if (classForTab.modified == 1) {
                setrowdata(modified_trucks);
            } else if (classForTab.planned == 1) {
                setrowdata(planned_trucks);
            } else {
                setrowdata(new_trucks);
            }
            setoriginalData(new_trucks)
            var work_order_no = []
            if (new_trucks != '' && new_trucks != undefined) {
                new_trucks.map((e) => {
                    work_order_no.push({ label: e.work_order_no, value: e.work_order_no })
                })
            }
            setworkOrderList(work_order_no)
            setloadshow("show-n");
            setTimeout(() => {
                setLoadData(false);
            }, 1000);
        }).catch(e => {
            console.log("Error :", e)
            setloadshow("show-n");
            setTimeout(() => {
                setLoadData(false);
            }, 1000);
        });
    }
    const onGridReady = params => {
        gridApi.current = params;
        gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
    const onClickAcknowledgeBtn = (rowdata) => {
        var row = rowdata.data
        //console.log(row, "recordrow")
        var params = { work_order_no: row.work_order_no, unsetField: 'cha_do_modified', setField: 'cha_do_planned', record: row, tpt_trucks_modified:1 ,
            screenname: "transporter_update_trucks_details",
            actionType: "Acknowledgement",
            userId: localStorage.getItem('userid'),
            email: localStorage.getItem("email"),
            username: localStorage.getItem("user_name"),
            role: localStorage.getItem("role"),
        }
        redirectURL.post('/consignments/acknowledgeData', params).then((resp) => {
            if (resp.data.status == 'Success') {
                setshow(true)
                setbasicTitle("Acknowledgement successful")
                setbasicType("success")
                // setclassforTab(
                //     {
                //         // Redirecting to Planned Section
                //         planned: 1,
                //         new: 0,
                //         modified: 0,
                //     }
                // )
                props.onRequest()
                props.onReloadData(true);
            }
        })
    }
    const onClickHideManualUpload3 = () => {
        setshowAddForm3('')
        setoverly('show-n')
        onClickHideManualUpload3("")
    }
    const onShowShipmentLegRouteMap = async (e) => {
        var params = {
            work_order_no: e.work_order_no,
            truck_no: e.truck_no,
            container_no: e.container_no
        };
        setloadshow('show-m');
        setoverly('show-m');

        try {
            const resp = await redirectURL.post('/consignments/getmapdata', params);
            //console.log(resp.data);
            var gps_route = resp.data.gps_route.coords;
            if (!Array.isArray(gps_route)) {
                gps_route = gps_route.replace(/NaN/g, "0");
                gps_route = JSON.parse(gps_route);
            }
            if (!gps_route || gps_route.length === 0) {
                setshow(true);
                setbasicTitle('No GPS Data Available');
                setbasicType('danger');
                setloadshow('show-n');
                setoverly('show-n');

            } else {
                var allCoordinates = [];
                var allldbcoords = [];
                var gps_route =gps_route;
                var ldb_data = resp.data.ldb_route
                var ldb_route = ldb_data.ldb_route;

                //console.log('gps_route', gps_route);
                //console.log('ldb_route', ldb_route);
                if (ldb_route != '' && ldb_route != undefined) {
                    if (ldb_route.length > 0) {
                        ldb_route = JSON.parse(ldb_route)
                        ldb_route.forEach((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;

                            allldbcoords.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('ldb_route is not an array or is empty.');
                    }
                }
                //console.log(gps_route, 'gps_routegps_route')
                if (gps_route != '' && gps_route != undefined) {
                    if (gps_route.length > 0) {
                        //console.log(typeof gps_route, 'gps_routegps_route')
                        gps_route.map((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;
                            allCoordinates.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('gps_route is not an array or is empty.');
                    }
                }

                var truck_no;
                if (gps_route.length > 0) {
                    truck_no = gps_route[0].truck_no;
                }
                //console.log(allCoordinates, 'allCoordinates');
                setoverly('show-m')
                setStateForMap((prev) => ({
                    ...prev,
                    sliderRouteTranslate: "slider-translate-75p",
                    showDiv: 'show-m',
                    container_no: truck_no,
                    mapinfo: {
                        ...state.mapinfo,
                        coords: allCoordinates,
                        ldb_route: allldbcoords
                    },
                    mapdata:resp.data.gps_route
                }));
                setloadshow('show-n');
            }
        } catch (error) {
            console.error(error);
        }
    };
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const updateBtn = () => {
        setshowAddForm("slide30")
        setoverly("show-m")
    }
    const onSelectWON = (e) => {
        //console.log(e)
        setmanualUploadDetails(prev => ({
            ...prev,
            wrno: e
        }))
    }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')
        setmanualUploadDetails(prev => ({
            ...prev,
            trucks: [],
            wrno: { label: '', value: '' },
            confirm: { label: '', value: '' }
        }))
    }
    const onClickHideManualUpload1 = () => {
        setshowAddForm1('')
        setoverly('show-n')
        setnewtruck('')
        setselectedtrucksforeditlist([])
    }
    const onClickHideManualUpload2 = () => {
        setshowAddForm4("");
        setoverly("show-n");
        setmanualUploadDetails1((prev) => ({
          ...prev,
          work_order_no: "",
          new_work_order_no: "",
          truck_no: "",
        }));
        setreassignbtnenable(false);
        setnewworkordercheck(false);
        setcheckforsubmit(false);
        settruckList([])
      };
    var onClickSaveGridState = () => {
        try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = '/updatetrucksnew'
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: '/updatetrucksnew',
                screentitle: screenpage
            }
            //console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    //console.log("Error ", e)
                })
            //console.log('hit')
        }
        catch (e) {
            //console.log(e)
        }
    }
    const resetState = () => {
        try {
            // Reset columns to their initial state (order, visibility, width)
            console.log(defaulColumnDefs,'defaulColumnDefs')
            setsummaryViewcols(defaulColumnDefs)
            const initialColumnState = defaulColumnDefs.map(col => ({
                colId: col.field,
                hide: col.hide || false,
                ...col
            }));
            // Apply the column state to reset order and visibility
            gridColumnApi.current.applyColumnState({
                state: initialColumnState,
                applyOrder: true  // This ensures that the column order is also reset
            });
            gridApi.current.api.refreshCells();
            setsummaryViewcols(defaulColumnDefs)
            // After the reset, you may want to fetch the user grid state from the backend
            const reqparams = {
                userId: localStorage.getItem("userid"),
                screenurl: "/updatetrucksnew",
                screentitle: '/updatetrucksnew',  // Adjust to your actual screen title
            };
            redirectURL.post("/consignments/removeUserGridState", reqparams)
                .then((response) => {
                    setshow(true);
                    setbasicTitle("Successfully reset default grid layout");
                    setbasicType("success");
                    // Optionally, reload grid state from the backend (if needed)
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000);
                })
                .catch((error) => {
                    setshow(true);
                    setbasicTitle("Error resetting grid layout");
                    setbasicType("error");
                    console.error('Error resetting grid state:', error);
                });
        } catch (error) {
            console.error('Unexpected error in resetState:', error);
        }
    };
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: '/updatetrucksnew' , userId:localStorage.getItem("userid")})
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols= fetchGrid(summaryViewcols, grid[0].gridcolumns)
                //console.log("oCols",oCols)
            })
    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols= fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            //console.log(e);
        }
    };
    const onGridState = () => {
        ////console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */

        window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


    }
    const onsubmitdata3 = (e) => {
        e.preventDefault();
        //console.log(selectedWorkOrder, "0000");
        var trucks_count = selectedWorkOrder[0].total_trucks;
        //console.log(trucks_count, "9999");
        if (reassignbtnenable == true) {
          setnewworkordercheck(true);
          //    setreassignbtnenable(false)
          setcheckforsubmit(true);
        } else {
          var selectedtrucks = manualUploadDetails1.truck_no;
          var truckList = [];
          selectedtrucks.map((e) => {
            truckList.push(e.value);
          });
          var params = {
            work_order_no: manualUploadDetails1.work_order_no.value,
            truck_no: truckList,
            screenname: "transporter_update_trucks_details",
            actionType: "Assigned Trucks to Work Order",
            userId: localStorage.getItem('userid'),
            email: localStorage.getItem("email"),
            username: localStorage.getItem("user_name"),
            role: localStorage.getItem("role"),
          };
          var truck_no_len = truckList.length;
          // //console.log(container_no_len, "len")
          //console.log(truck_no_len === trucks_count, "condition");
          
          if (truck_no_len === trucks_count) {
            redirectURL
              .post("/consignments/assigntrucks", params)
              .then((resp) => {
                if (resp.data.message == "success") {
                  setshow(true);
                  setbasicTitle("Successfully Assigned");
                  setbasicType("success");
                  setshowAddForm4("");
                  setoverly("show-n");

                  setmanualUploadDetails1((prev) => ({
                    ...prev,
                    work_order_no: "",
                    new_work_order_no: "",
                    truck_no: "",
                  }));
                    setreassignbtnenable(false);
                    setnewworkordercheck(false);
                    setcheckforsubmit(false);
                }
              });
          } else {
            setshow(true);
            setbasicTitle("Please Provide Appropriate No of Trucks");
            setbasicType("danger");
            setloadshow("show-n");
            setoverly("show-n");
          }
        }
      };
    const onsubmitdata1=(e)=>{
        e.preventDefault()
        //console.log(selected_row,"selected_row")
        //console.log(newtruck,'newtrucks')
        //console.log(selectedtrucksforeditlist,'selectedtrucksforeditlist')
        var newtrucks = []
        if(newtruck!='' && newtruck!=undefined){
            if(newtruck.length>0){
                newtruck.map((e)=>{
                    newtrucks.push(e.value)
                })
            }
        }
        var filteredSelected = assignedtruckslist.filter(item=>selectedtrucksforeditlist.includes(item) )
        if(selected_row.tpt_container_planned!=1 ){
            var params = {
                data:{
                 work_order_no:selected_row.work_order_no,
                 trucks:newtrucks,
                 old_trucks : filteredSelected  || [],
                },
                screenname: "transporter_update_trucks_details",
                actionType: "Trucks Updated for Work Order",
                userId: localStorage.getItem('userid'),
                email: localStorage.getItem("email"),
                username: localStorage.getItem("user_name"),
                role: localStorage.getItem("role"),
             }
            redirectURL.post('/consignments/edittrucksdetails',params).then((resp)=>{
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Trucks Updated Successfully")
                    setclassforTab(
                        {
                            planned: 0,
                            new: 1,
                            modified: 0,
                        }
                    )
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm1('')
                    props.onRequest('1')
                    setnewtruck('')
                    // props.onRequest('2')
                    // setreloadcheck(true)
                }else{
                    setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                }                
            }).catch((error) => {
                setshow(true)
                setbasicTitle(error.response.data.message)
                setbasicType("danger")
                setloadshow('show-n')                
        });
        }
        else if(selected_row.container_count_increase_check==1 ){
            var params = {
                data:{
                 work_order_no:selected_row.work_order_no,
                 trucks:newtrucks,
                 old_trucks : filteredSelected  || [],
                //  'container_count_increase_check':1
                },
                screenname: "transporter_update_trucks_details",
                actionType: "Trucks Updated for Work Order",
                userId: localStorage.getItem('userid'),
                email: localStorage.getItem("email"),
                username: localStorage.getItem("user_name"),
                role: localStorage.getItem("role"),
             }
            redirectURL.post('/consignments/edittrucksdetails',params).then((resp)=>{
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Trucks Updated Successfully")
                    setclassforTab(
                        {
                            planned: 0,
                            new: 1,
                            modified: 0,
                        }
                    )
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm1('')
                    props.onRequest('1')
                    setnewtruck('')
                    // props.onRequest('2')
                    // setreloadcheck(true)
                }else{
                    setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                }                
            }).catch((error) => {
                setshow(true)
                setbasicTitle(error.response.data.message)
                setbasicType("danger")
                setloadshow('show-n')                
        });
        }
        else{
            setshow(true)
            setbasicTitle("Container No Already Assigned")
            setbasicType("danger")
            setloadshow('show-n')
            setoverly('show-n')
            setshowAddForm1('')
        }
    }
    const selectedtruckforedit = (e) => {
        const { value, checked } = e.target;  // Extract value and checked state
        if (checked) {
            setselectedtrucksforeditlist((prev) => [...prev, value]);
        } else {
            setselectedtrucksforeditlist((prev) =>
                prev.filter((t) => t !== value)
            );
        }
    }
    // const onsubmitdata = (e) => {
    //     e.preventDefault()
    //     var trucks = manualUploadDetails.trucks
    //     var truckList = []

    //     if (trucks != '' && trucks != undefined) {
    //         trucks.map((e) => {
    //             truckList.push(e.value)
    //         })
    //     }
    //     // var wrno = manualUploadDetails.wrno.value
    //     var wrno = selected_row.work_order_no
    //     var record = originalData.filter(e => e.work_order_no == wrno)
    //     //console.log(record, '104')
    //     record = record[0]

    //     var containerCount = record.container_count
    //     //console.log(containerCount, 'containerCount')
    //     var params = {
    //         work_order_no: selected_row.work_order_no,
    //         trucks: truckList
    //     }
    //     //console.log(checkfordisableconfirm,'checkfordisableconfirm')
    //     //console.log(truckList,'truckList')
    //     if(checkfordisableconfirm==1&&truckList.length==0){
    //         setshow(true)
    //             setbasicTitle("Successfully Updated!")
    //             setbasicType("success")
    //             setloadshow('show-n')
    //             setoverly('show-n')
    //             props.onRequest('1')
    //     }
    //     else if(checkfordisableconfirm==0&&truckList.length==0){
    //         setshow(true)
    //         setbasicTitle('Please Select Trucks')
    //         setbasicType("danger")
    //         setloadshow('show-n')
    //         setoverly('show-n')
    //     }
    //     else{
    //         redirectURL.post('/consignments/updateTrucksDetails', params).then((resp) => {
    //             if (resp.data.status == 'Success') {
    //                 setshow(true)
    //                 setbasicTitle(resp.data.message)
    //                 setbasicType("success")
    //                 setloadshow('show-n')
    //                 setoverly('show-n')
    //                 props.onRequest('1')
    //                 setmanualUploadDetails(prev => ({
    
    //                     ...prev,
    //                     trucks: [],
    //                     wrno: { label: '', value: '' },
    //                     confirm: { label: '', value: '' }
    //                 }))
    //                 setselected_row({})
    //                 setshowAddForm('')
    //                 if (selected_row.tpt_trucks_planned != 1) {
    
    //                 }
    //             }
    //             else {
    //                 setshow(true)
    //                 setbasicTitle(resp.data.message)
    //                 setbasicType("danger")
    //                 setloadshow('show-n')
    //                 setoverly('show-n')
    //             }
    //         })
         
    //     }
       

       
    // }
    const onsubmitdata = (e) => {
        e.preventDefault()
        var trucks = manualUploadDetails.trucks
        var truckList = []

        if (trucks != '' && trucks != undefined) {
            trucks.map((e) => {
                truckList.push(e.value)
            })
        }
       
        // var wrno = manualUploadDetails.wrno.value
        var wrno = selected_row.work_order_no
        var record = originalData.filter(e => e.work_order_no == wrno)
        //console.log(record, '104')
        record = record[0]

        var containerCount = record.container_count
        //console.log(containerCount, 'containerCount')
        var params = {
            data: {
                work_order_no: selected_row.work_order_no,
                trucks: truckList,
            },
            screenname: "transporter_update_trucks_details",
            actionType: "Assigned Trucks to Work Order",
            userId: localStorage.getItem('userid'),
            email: localStorage.getItem("email"),
            username: localStorage.getItem("user_name"),
            role: localStorage.getItem("role"),
        }
        //console.log(checkfordisableconfirm,'checkfordisableconfirm')
        //console.log(truckList,'truckList')
        redirectURL.post('/consignments/updateTrucksDetails', params).then((resp) => {
            if (resp.data.status == 'Success') {
                setshow(true)
                setbasicTitle('Truck(s) assigned successfully')
                setbasicType("success")
                setloadshow('show-n')
                setoverly('show-n')
                props.onRequest('1')
                setmanualUploadDetails(prev => ({

                    ...prev,
                    trucks: [],
                    wrno: { label: '', value: '' },
                    confirm: { label: '', value: '' }
                }))
                setselected_row({})
                setshowAddForm('')
                if (selected_row.tpt_trucks_planned != 1) {

                }
            }
            else {
                setshow(true)
                setbasicTitle(resp.data.message)
                setbasicType("danger")
                setloadshow('show-n')
                setoverly('show-n')
            }
        })

       
    }
    const onSelectConfirm = (e) => {
        if (e.value == 'yes') {
            checkfortruck = 1
            setcheckfordisableconfirm(1)
        }
        else {
            setshowAddForm('')
            setoverly('show-n')
            const isConfirmed = window.confirm('Do you want to reject this workorder?');
            if (isConfirmed) {
                var params = {
                    work_order_no: selected_row.work_order_no,
                    rejected_tpt:selected_row.transporter_name ,
                    screenname: "transporter_update_trucks_details",
                    actionType: "Rejected Work Order for Trucks Assign",
                    userId: localStorage.getItem('userid'),
                    email: localStorage.getItem("email"),
                    username: localStorage.getItem("user_name"),
                    role: localStorage.getItem("role"),
                }
                //console.log(params)
                redirectURL.post('/consignments/returnWorkorder', params).then((resp) => {
                    if (resp.data.status == 'success') {
                        setshow(true)
                        setbasicTitle("This Workorder has been rejected")
                        setbasicType("success")
                        props.onRequest('1')
                    }
                })
            }
            else {

            }
        }
        setmanualUploadDetails(prev => ({
            ...prev,
            confirm: e
        }))
        // setselectedconfirm(e)
    }
    const handleTrucksList = (e) => {
        //console.log(e, 'ee')
        setmanualUploadDetails(prev => ({
            trucks: e
        }))
    }
    const onhandlenewtrucks = (e)=>{
        setnewtruck(e)
    }
    var onCloseRouteDiv = () => {
        setStateForMap(prevState => ({
            showDiv: 'show-n',
            sliderRouteTranslate: "",
        }))
        setoverly('show-n')
        setloadshow('show-n')
    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    
    const onclickTab = (e) => {
        //console.log("setclassfortab",tabDatafortrucks.trucks_modified);
        if (e == 'New') {
            setclassforTab({
                new: 1,
                planned: 0,
                modified: 0
            })
            setrowdata(tabDatafortrucks.trucks_new)
            setoriginalData(tabDatafortrucks.trucks_new)
        }
        else if (e == 'Planned') {
            setclassforTab({
                new: 0,
                planned: 1,
                modified: 0
            })
            setrowdata(tabDatafortrucks.trucks_planned)
            setoriginalData(tabDatafortrucks.trucks_planned)
        }
        else if (e == 'Modified') {
            setclassforTab({
                new: 0,
                planned: 0,
                modified: 1
            })
            setrowdata(tabDatafortrucks.trucks_modified)
            setoriginalData(tabDatafortrucks.trucks_modified)
        }
    }
    // if(selected_row.tpt_trucks_planned==1&&selected_row.tpt_container_new==1&&selected_row.tpt_trucks_new!=1){
    //      var hideforcheck=true
    //     }
    //     else{
    //        var hideforcheck=false
    //     }

    const getRowClass = (params) => {
        //console.log(params, '185')
        if (params.data && (params.data.ib_tpt_modified == 1 || params.data.modified_plant == 1 || params.data.cha_vessel_modified == 1 || params.data.ib_stuffing_modified == 1 || params.data.cha_do_modified == 1)) {
            return { backgroundColor: '#FFB9B9' };
        }
        return null
    }
    var onClickShowShipmentLegs = async (rownode) => {
        var commentHeaders = [
            {
                headerName: "",
                field: "",
                resizable: true,
                width: 60,
                filter: true,
                // cellRendererFramework: LegRouteActiveTruckMap,
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <button onClick={() => onShowShipmentLegRouteMap(params.data)} className="custom-btn label label-success">
                                <i className="icofont icofont-map-pins f20"></i>
                            </button>
                        </div>
                    );
                },
            },
            // {
            //     headerName: 'Requirement Id',
            //     field: "requirement_id",
            //     resizable: true,
            //     width: 180,
            //     filter: true
            // },
            {
                headerName: 'Work Order No',
                field: "work_order_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Truck No',
                field: "truck_no",
                resizable: true,
                width: 180,
                filter: true
            },
        ]
        var setdata = {
            detailGridOptions: {
                columnDefs: commentHeaders,
                overlayNoRowsTemplate: 'No rows to show',
                detailRowHeight: 50,
            },
            getDetailRowData: async function (param) {
                param.successCallback([]);
                var row = param.data
                var parameter = {
                    work_order_no: row.work_order_no
                }
                // var records = containerLegsData.filter(e =>e.work_order_no == row.work_order_no)
                await redirectURL.post("/consignments/truckslegsdata", parameter).then(async (response) => {
                    //console.log(response.data, 'response')
                    var records = response.data
                    // records = records.filter(e=>e.containerDetailscheck!=1)
                    param.successCallback(records);
                }).catch(function (error) {
                    //console.log(error);
                })
            },
            masterDetail: true,
            detailRowHeight: 50,
            rowHeight: 50
        }
        dispatch({ type: 'SET_DETAIL_RENDERER_PARAMS', payload: setdata });
        setTimeout(() => {
            if (rownode.column.colDef.field == 'containerLegs') {
                rownode.node.setExpanded(!rownode.node.expanded);
                rownode.node.setRowHeight(50)
            }
            else {
                rownode.node.setExpanded(false);
            }
        }, 0)
    }
    const onClickDOdetails=(params)=>{
        //console.log('163',params)
        setshowAddForm3("slide33")
        //console.log(params.data.attachmentFileName)
        setwrdo(params.data.work_order_no)
        const {attachmentFileName} = params.data
        setDownLoadFile1Name(attachmentFileName)
        //console.log(params.data.additionl_do_copy_arr);
        setFile1({file1:params.data.uploaded_file,
            file2:params.data.additionl_do_copy_arr|| [],})
    }
    const onClickedittruck = (e) => {
        setselected_row(e.data)
        setshowAddForm1("slide45")
        setoverly("show-m")
        redirectURL.post('/consignments/getassignedtrucks', { 'work_order_no': e.data.work_order_no }).then((resp) => {
            //console.log(resp.data, '604')
            if (resp.data != '' && resp.data != undefined) {
                setassignedtrucksList(resp.data)
            }
        })
        redirectURL.post('/consignments/getassignedtransporter', { 'work_order_no': e.data.work_order_no }).then((resp) => {
            var trucksrecord = resp.data
            var trucks = []
            trucksrecord.map((e) => {
                trucks.push({ label: e, value: e })
            })
            settrucksList(trucks)
        })
    }
    const onClickentertruckbtn = (e) => {
        setselected_row(e.data)
        setshowAddForm("slide30")
        setoverly("show-m")
        redirectURL.post('/consignments/getassignedtrucks', { 'work_order_no': e.data.work_order_no }).then((resp) => {
            if (resp.data != '' && resp.data != undefined) {
                setassignedtrucksList(resp.data)
            }
        })
        redirectURL.post('/consignments/getassignedtransporter', { 'work_order_no': e.data.work_order_no }).then((resp) => {
            var trucksrecord = resp.data
            var trucks = []
            trucksrecord.map((e) => {
                trucks.push({ label: e, value: e })
            })
            settrucksList(trucks)
        })
    }

    const detailedviewcols = []
    var uploadtrucks = {
        headerName: "Update",
        field: "update_trucks_btn",
        width: 80,
        pinned: 'left',
        cellRenderer: 'UpdatetrucksBtn'
    }
    // var hideforcheck = false
    // if(selected_row.tpt_container_planned==1){
    //     hideforcheck=true
    //     }
    var edittruck = {
        headerName: "Edit Trucks",
        field: "",
        width: 140,
        pinned: 'left',
        // hide:function(params){
        //     return params.data.tpt_container_planned==1
        // },
        cellRenderer: 'EdittruckComponent'
    }
    var acknowledgebtn = {
        headerName: "",
        field: "",
        width: 120,
        filter: true, resizable: true,
        pinned: 'left',
        cellRendererFramework: AcknowledgeBtnComponent
    }
    var legsfortrucks = [
        {
            headerName: "",
            field: "containerLegs",
            width: 60,
            pinned: 'left',
            cellRenderer: 'ConsignmentTrucksLegs'
        }
    ]
    var docopy=  {
        headerName: "DO Copy",
        field: "do_copy",
        width: 100,
        pinned:'left',
        resizable: true,
        cellRenderer:ViewDOdetailsComponent
    }
    var columns1 = [...summaryViewcols]
    if (classForTab.new == 1) {
        columns1.push(uploadtrucks,docopy)
        detailedviewcols.push(uploadtrucks,docopy)
    }
    if (classForTab.planned == 1) {
        columns1.push(...legsfortrucks)
        detailedviewcols.push(...legsfortrucks)
        columns1.push(edittruck,docopy)
        detailedviewcols.push(edittruck,docopy)
        const columnToRemove = 'remaining_trucks'; // Replace this with the actual field name of the column to remove
        columns1 = columns1.filter(column => column.field !== columnToRemove);
    }
    if (classForTab.modified == 1) {
        columns1.push(acknowledgebtn)
        columns1.push(edittruck)
        detailedviewcols.push(edittruck)
        // if(selected_row.tpt_container_planned!=1&&selected_row.tpt_container_new==1){
        //     columns1.push(edittruck)
        // detailedviewcols.push(edittruck)
        // }
    }
    if (classForTab.new==1){
        const pendencyIndex = columns1.findIndex(col=>col.field==="remaining_trucks");

        if(pendencyIndex!==-1){
            columns1[pendencyIndex].hide=false;
        }
    }
    var downloadBtn = "#1976d2";
     var  cursorPoint = "pointer";
    const columnwithDefs = summaryviewcheck == 1 ? columns1 : detailedviewcols
    var newCount = tabDatafortrucks.trucks_new
    var plannedCount = tabDatafortrucks.trucks_planned
    var modifiedCount = tabDatafortrucks.trucks_modified
    var selectedtrucks = assignedtruckslist.map(e => e).join(', ')
    const downloadB1=(e)=>{
        //console.log(files1, "filessssss1");
                var fileparams = {
                    attachmentFileName : files1.file1,
                    work_order_no:wrdo,
                    file1: 1
                }
                //console.log(fileparams, "fileparamsssss1")
                redirectURL.post("/consignments/downloadDOFileFile",fileparams,{
                }).then((response)=>{
                    const imageURL = response.data;
                     window.open(imageURL, '_blank');
                }).catch(function(error){
                    //console.log(error);
                })
    }
//     const downloadB2=(e)=>{
//         // var fileparams = {
//         //     attachmentFileName : files1.file2,
//         //     work_order_no:wrdo,
//         //     file1: 2
//         // }
//         // //console.log(fileparams, "fileparamsssss2")

//         // redirectURL.post("/consignments/downloadDOFileFile",fileparams,{
//         // }).then((response)=>{
//             const imageURL = e;
//              window.open(imageURL, '_blank');
//         // }).catch(function(error){
//         //     //console.log(error);
//         // })
// }
const downloadB2 = (fileName = "") => {
    if (fileName === "") {
      return;
    }
 
    redirectURL
      .post("/consignments/downloadFile", {
          fileName: fileName}
      )
      .then((res) => {
        // //console.log(res);
        if (res.status === 200) {
          const downloadUrl = res.data.url;

          
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", fileName); // Optional: specify download filename
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // //console.log("clicked");
        }
      })
      .catch((err) => {
        //console.log("err - ", err);
      });
  };
var onClickselectdetails = () => {
    setshowAddForm4("slide30");
    setoverly("show-m");
  };
  const getsetrowData = ()=>{
    redirectURL.post("/consignments/getAllConsignmentsData").then((resp)=>{
        //console.log(resp, "resp")
        var transporterDetails = resp.data.work_order_level_data.filter(e => e.tpt_trucks_planned == 1 || e.tpt_trucks_new == 1 || e.tpt_trucks_modified == 1);

        var planned_trucks = transporterDetails.filter(e => e.tpt_trucks_planned == 1);
        var new_trucks = transporterDetails.filter(e => e.tpt_trucks_new == 1)
        var modified_trucks = transporterDetails.filter(e => e.tpt_trucks_modified == 1)
        settabDatafortrucks({
            trucks_new: sortByCreatedDate(new_trucks),
            trucks_planned: sortByCreatedDate(planned_trucks),
            trucks_modified: sortByCreatedDate(modified_trucks)
        })

    })
    props.onRequest()
}
  var handlewrno = (e) => {
    setmanualUploadDetails1((prev) => ({ ...prev, work_order_no: e }));
    var params = {
      work_order_no: e.value,
    };
    // redirectURL.post('/consignments/getcontainercount', params).then((resp) => {
    //     var records = resp.data
    //     //console.log(records, "wr_details")
    // })
   
    

    
    redirectURL
      .post("/consignments/getspecificcontainer", params)
      .then((resp) => {
        var truckList = resp.data.trucks_list;
        //console.log(truckList, "truckklisttt")
        if (truckList != "" && truckList != undefined) {
          if (truckList.length > 0) {
            var trucks = [];
            truckList.map((e) => {
              trucks.push({ label: e.truck_no, value: e.truck_no });
            });
            settruckList(trucks);
            var wr_details = resp.data.work_order_details;
            //console.log(wr_details, "selectedwr_no");
            setSelectedWorkOrder(wr_details);
            if (wr_details[0].assigned_truck_check == 1) {
              setreassignbtnenable(true);
            } else {
              setreassignbtnenable(false);
            }
            var wrForReassign = resp.data.reassign_wr_trucks;
            var reassign_wr = [];
            wrForReassign.map((e) => {
              reassign_wr.push({
                label: e.work_order_no,
                value: e.work_order_no,
              });
            });
            setwrlist1(reassign_wr);
          }
        } else {
          setreassignbtnenable(false);
        }
      });
  };
  var handlewrno1 = (e) => {
    setmanualUploadDetails1((prev) => ({ ...prev, new_work_order_no: e }));
    var params = {
      work_order_no: e,
    };
    // redirectURL.post('/consignments/getreassignedworkorder', params).then((resp) => {
    //     var records = resp.data.records
    //     //console.log(records, "selectnewwrrr")
    // })
    setcheckforsubmit(true);
  };
  const onsubmitdata2 = (e) => {
    e.preventDefault();
    //console.log(selectedWorkOrder, "0000");
    // var truck_count = selectedWorkOrder[0].total_trucks;
    //console.log(truck_count, "9999");
   
      var selectedtrucks =  manualUploadDetails1.truck_no || [];
      var truckList = [];
      selectedtrucks.map((e) => {
        truckList.push(e.value);
      });
      var params = {
          work_order_no: manualUploadDetails1.work_order_no.value,
          truck_no: truckList,
          screenname: "transporter_update_trucks_details",
          actionType: "Trucks Released from Work Order",
          userId: localStorage.getItem('userid'),
          email: localStorage.getItem("email"),
          username: localStorage.getItem("user_name"),
          role: localStorage.getItem("role"),
      };
      var truck_no_len = truckList.length;
      // //console.log(container_no_len, "len")
      //console.log(truck_no_len === truck_count, "condition");
      if (truck_no_len>0) {
        redirectURL
          .post("/consignments/releasetrucks", params)
          .then((resp) => {
              if (resp.data.message == "success") {
                  setshow(true);
                  setbasicTitle("Successfully Released");
                  setbasicType("success");
                  setshowAddForm1("");
                  setshowAddForm4("");
                  setoverly("show-n");
                  setreassignbtnenable(false);
                  setnewworkordercheck(false);
                  setcheckforsubmit(false);
                  setmanualUploadDetails1((prev) => ({
                      ...prev,
                      work_order_no: "",
                      new_work_order_no: "",
                      truck_no: "",
                  }));
                  settruckList([])
                  props.onRequest()
                  props.onReloadData(true);
              }
          });
      } else {
        setshow(true);
        setbasicTitle("Please Provide Appropriate No of Trucks");
        setbasicType("danger");
        setloadshow("show-n");
        setoverly("show-n");
      }
    
  };
    const onClickreassignworkorder = async (e) => {
        e.preventDefault();
        var selectedtrucks = manualUploadDetails1.truck_no;

        var truckList = [];
        selectedtrucks.map((e) => {
            truckList.push(e.value);
        });
        var params = {
            work_order_no: manualUploadDetails1.new_work_order_no.value,
            truckList: truckList,
            reassigned: 1,
            screenname: "transporter_update_trucks_details",
            actionType: "Trucks Re-assigned to Work Order",
            userId: localStorage.getItem('userid'),
            email: localStorage.getItem("email"),
            username: localStorage.getItem("user_name"),
            role: localStorage.getItem("role"),
        };
        await redirectURL
            .post("/consignments/reassigntrucks", params)
            .then((resp) => {
                if (resp.data.message == "success") {
                    setshow(true);
                    setbasicTitle("Successfully Assigned");
                    setbasicType("success");
                    setshowAddForm4("");
                    setoverly("show-n");
                    setreassignbtnenable(false);
                    setnewworkordercheck(false);
                    setcheckforsubmit(false);
                    props.onRequest()
                    // props.onReloadData(true);
                }
            });

        var work_order_no1 = manualUploadDetails1.work_order_no.value;
        var params = {
            work_order_no: work_order_no1
        }

        await redirectURL.post("/consignments/getreassign", params).then((resp) => {
            var data = resp.data.records1;
            var data3 = resp.data.records_consignments;
            //console.log(data3, "reassign");
            const sumValues = data.reduce(
                (acc, obj) => {
                    acc.assigned_truck += obj.assigned_truck || 0;
                    acc.reassigned += obj.reassigned || 0;
                    acc.release_wr += obj.release_wr || 0;
                    return acc;
                },
                { assigned_truck: 0, reassigned: 0, release_wr: 0 }
            );

            //console.log("Sum of assigned_truck:", sumValues.assigned_truck);
            //console.log("Sum of reassigned:", sumValues.reassigned);
            //console.log("Sum of release_wr:", sumValues.release_wr);

            if (isNaN(sumValues.assigned_truck)) {
                sumValues.assigned_truck = 0;
            }
            if (isNaN(sumValues.reassigned)) {
                sumValues.reassigned = 0;
            }
            if (isNaN(sumValues.release_wr)) {
                sumValues.release_wr = 0;
            }

            var truck_count_assigned =
                sumValues.assigned_truck +
                sumValues.reassigned +
                sumValues.release_wr;

            //console.log(truck_count_assigned, "truck_Counttttt");

            var truck_count_wr = data3[0].old_truck_dec_count;
            //console.log(data3, "data3")
            //console.log(truck_count_wr, "containerrrr")
            //console.log(truck_count_assigned === truck_count_wr, "conditionnnnnn")
            if (truck_count_assigned === truck_count_wr) {
                setclassforTab({
                    new: 1,
                    planned: 0,
                    modified: 0
                })
                var params2 = {
                    work_order_no: data3[0].work_order_no
                }
                redirectURL.post("/consignments/removetruckmodified", params2).then((resp) => {
                    //console.log(resp, "resp")
                    getsetrowData()
                })
            }
        });
    };
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            {/* <div className="float-right" >
                        <button className='btn_style' onClick={updateBtn} >Update</button>
                     </div> */}
            <div className="" style={{ margin: '10px', marginTop: '12px', width: "100%", height: "478px" }}>
                    <div className="d-flex flex-row" style={{position:"relative"}}>
                        <button
                            className="d-flex flex-row justify-content-center align-items-center px-3"
                            style={{
                                background: classForTab.new == 1 ? '#0664AE' : '#FFFFFF',
                                // color: state.isTileView ? "": "#0664AE",
                                borderTopLeftRadius: "11px",
                                borderBottomLeftRadius: "11px",
                                border: "1px solid #0664AE",
                                height: "38px",
                                padding: '22px'
                            }}
                            onClick={() => onclickTab('New')}
                        >
                            <div
                                style={{
                                    color: classForTab.new == 1 ? "white" : '#0664AE',
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    marginLeft: "10px",
                                    lineHeight: "12px",
                                }}
                            >
                                New - <span>{(newCount != '' && newCount != undefined) ? newCount.length : 0}</span>
                            </div>
                        </button>

                        <button
                            className="d-flex flex-row justify-content-center align-items-center px-3"
                            style={{
                                background: classForTab.planned == 1 ? '#0664AE' : "#FFFFFF",
                                border: "1px solid #0664AE",
                                height: "38px",
                                padding: '22px'
                            }}
                            onClick={() => onclickTab('Planned')}
                        >
                            <div
                                style={{
                                    color: classForTab.planned == 1 ? "white" : '#0664AE',
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    marginLeft: "10px",
                                    lineHeight: "12px",
                                }}
                            >
                                Planned - <span>{(plannedCount != '' && plannedCount != undefined) ? plannedCount.length : 0}</span>
                            </div>
                        </button>
                        <button
                            className="d-flex flex-row justify-content-center align-items-center px-3"
                            style={{
                                background: classForTab.modified == 1 ? '#0664AE' : "#FFFFFF",
                                borderTopRightRadius: "11px",
                                borderBottomRightRadius: "11px",
                                border: "1px solid #0664AE",
                                height: "38px",
                                padding: '22px'
                            }}
                            onClick={() => onclickTab('Modified')}
                        >
                            <div
                                style={{
                                    color: classForTab.modified == 1 ? "white" : '#0664AE',
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    marginLeft: "10px",
                                    lineHeight: "12px",
                                }}
                            >
                                Modified - <span>{(modifiedCount != '' && modifiedCount != undefined) ? modifiedCount.length : 0}</span>
                            </div>
                        </button>
                        {classForTab.new===1 ?
                         <div style={{position:"absolute", right:"10px",top:"-25px",right:"3px" }}>
                            <button
                                type="button"
                                title="Save Grid Layout"
                                name="savegrid"
                                className="btn btn-danger action_btn"
                                style={{ height: "32px", marginTop: "30px", paddingBottom: "30px" }}

                                onClick={onClickSaveGridState}>
                                <i className="f12 icofont icofont-save"></i>
                            </button>
                            <button
                                style={{ height: "32px", marginTop: "30px", paddingBottom: "30px", marginLeft: "4px" }}
                                title="Reset Default Layout"
                                className="float-right btn white btn-info" onClick={resetState}>
                                <i className="icofont icofont-refresh"></i>
                            </button>
                        </div>
                     :''}
                    </div>
                {/* <div style={{ display: 'flex', justifyContent: 'end' }}>

                <p style={{ color: 'red' }}>Note: Modification Pending At Previous Action</p>
                </div> */}

                <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                    <AgGridReact
                     ref={gridApi}
                        columnDefs={columnwithDefs}
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            editable: false,
                            resizable: true,
                            menuTabs: ['filterMenuTab']
                            // rowHeight:80,
                        }}
                        // paddingTop={10}
                        // rowHeight={70}
                        // headerHeight={70}
                        rowData={rowData}
                        suppressRowClickSelection={true}
                        detailCellRendererParams={state.detailCellRendererParams}
                        frameworkComponents={{ UpdatetrucksBtn: UpdatetrucksBtn, ConsignmentTrucksLegs: ConsignmentTrucksLegs, CountryFlagComponent: CountryFlagComponent, AcknowledgeBtnComponent: AcknowledgeBtnComponent, EdittruckComponent: EdittruckComponent,ViewDOdetailsComponent:ViewDOdetailsComponent }}
                        enableCellChangeFlash={true}
                        suppressCellFlash={true}
                        enableRangeSelection={true}
                        paginationPageSize={50}
                        onGridReady={onGridReady}
                        onGridState={onGridState}
                        rowSelection={'multiple'}
                        masterDetail={true}
                        pagination={true}
                        sideBar={{
                            toolPanels: [
                                {
                                    id: "columns",
                                    labelDefault: "Columns",
                                    labelKey: "columns",
                                    iconKey: "columns",
                                    toolPanel: "agColumnsToolPanel"
                                },
                                {
                                    id: "filters",
                                    labelDefault: "Filters",
                                    labelKey: "filters",
                                    iconKey: "filter",
                                    toolPanel: "agFiltersToolPanel"
                                }
                            ]
                        }}
                        statusBar={{
                            statusPanels: [
                                // {
                                //     statusPanel: "agTotalAndFilteredRowCountComponent",
                                //     align: "left"
                                // },
                                // {
                                //     statusPanel: "agTotalRowCountComponent",
                                //     align: "center"
                                // },
                                { statusPanel: "agFilteredRowCountComponent" },
                                { statusPanel: "agSelectedRowCountComponent" },
                                { statusPanel: "agAggregationComponent" }
                            ]
                        }}
                        gridOptions={{
                            icons: {
                                // You can customize other icons as well
                                next: `<img src="${customNextIcon}" alt="Next" />`,
                                previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                first: `<img src="${customFirstIcon}" alt="First" />`,
                                last: `<img src="${customLastIcon}" alt="Last" />`,
                            },
                        }}
                        // ref={gridRef}
                        getRowStyle={getRowClass}
                        context={{onClickDOdetails, onClickedittruck, onClickShowShipmentLegs, onClickentertruckbtn, onClickAcknowledgeBtn }}
                    />

                </div>
            </div>
            <div className={"sliderBlock2 " + showAddForm} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Trucks</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <p className='mb-5p'><span >Work Order No: </span> {selected_row.work_order_no}</p>
                                        {selected_row.confirmcheck == 1?
                                        <p className='mb-5p'><span style={{ color: 'red' }}> Assigned Trucks: </span> {selectedtrucks}</p>:''}
                                    </div>
                                    {selected_row.confirmcheck == 1 ? '' :
                                        <div className="form-group col-xl-12 col-lg-12">
                                            <label className='mb-5p'>Confirm</label>
                                            <Select
                                                // placeholder="Change Activity Status"
                                                closeMenuOnSelect={true}
                                                value={manualUploadDetails.confirm}
                                                // multi={true}
                                                // className={"border-radius-0"} 
                                                onChange={(e) => onSelectConfirm(e)}
                                                style={{ borderRadius: "0px" }}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: "0.8rem",
                                                        color: "#6e707e",
                                                    }),
                                                    singleValue:(provided,state)=>({
                                                        ...provided,
                                                        fontSize:'0.9rem'
                                                    })
                                                }}
                                                options={selectconfirmn}
                                            />
                                        </div>}
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Trucks <span className="err-txt"></span></label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.trucks}
                                            isMulti="true"
                                            className={"border-radius-0"}
                                            onChange={(e) => handleTrucksList(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={trucksList}
                                        />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <div className={"sliderBlock2 " + showAddForm1} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Edit Trucks</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload1} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata1}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <p className='mb-5p'><span style={{ color: 'red' }}>Work Order No: </span> {selected_row.work_order_no}</p>
                                        <p className='mb-15p'><span style={{ color: 'red' }}> Assigned Trucks: </span></p>
                                        <div className="row">
                                            {assignedtruckslist.map((e, index) => (
                                                <div key={e} className="col-md-3">
                                                    <p>
                                                        <input type="checkbox" id={`checkbox-${index}`} value={e} onChange={selectedtruckforedit} checked={selectedtrucksforeditlist.includes(e)} className="custom-checkbox" />
                                                        <label style={{ fontSize: 'larger', marginLeft: '10px' }} htmlFor={`checkbox-${index}`}>{e}</label>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Select Trucks <span className="err-txt">*</span></label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            value={newtruck}
                                            isMulti="true"
                                            className={"border-radius-0"}
                                            onChange={(e) =>onhandlenewtrucks(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={trucksList}
                                        />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            {stateForMap.sliderRouteTranslate != '' ?
                <div className={"sliderBlock2 " + stateForMap.sliderRouteTranslate} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Truck No: {(stateForMap.container_no)}
                            <span className="float-right closebtn" style={{ marginRight: "100px" }} onClick={onCloseRouteDiv} >X</span>
                        </h4>
                    </div>
                    {/* <div className={"col-xl-11 col-lg-11 white-bg "} style={{padding:"10px"}}>
                                    <input type="checkbox" style={{marginLeft: "2em",marginBottom: "1em",}}  /> view markers
                                    <Mapcard2 
                                    //context={this} 
                                    mapFor={"truckgps"} 
                                    // tolls = {this.state.tolls}
                                    mapinfo={stateForMap.mapinfo} 
                                    //defTransitCoords={""} 
                                    mapHeight={"100vh"}
                                    mapdata={stateForMap.mapdata}
                                    fullscreenControl={true}
                                    geoLocShipmentsMap={state.geoLocShipmentsMap}
                                    />
                                   

                                </div>  */}
                    <div className="slide-r-body" >

                        <div className="row col-xl-11 col-lg-11 ml-0p n-p-0 p-30p">
                            {/* {//console.log("this.state.mapinfo ", this.state.mapinfo)} */}
                            <Mapcard2
                                mapinfo={stateForMap.mapinfo}
                                mapHeight={"100vh"}
                                mapFor={"truck"}
                                mapdata={stateForMap.mapdata}
                                geoLocShipmentsMap={state.geoLocShipmentsMap}

                            />
                        </div>
                    </div>


                </div>
                : ""
            }
             {classForTab.modified === 1 ? (
            <>
              <div style={{ position: "absolute", right: "30px", top: "260px" }}>
                {/* <div className='btn_hover1' style={{ height: "35px", marginTop: "32px", fontSize: "12px", textAlign: "center", paddingTop: "8px", padding: "5px" }} onClick={onClickselectdetails}>
                                    exception
                                </div> */}
                <button
                  type="button"
                  className="f12 btn btn-outline-primary"
                  style={{
                    height: "35px",
                    marginTop: "32px",
                    fontSize: "12px",
                    textAlign: "center",
                    paddingTop: "8px",
                    padding: "5px",
                  }}
                  onClick={onClickselectdetails}
                >
                  Trucks Reduced
                </button>
                {/* <button type="button" className="f12 mr-5p btn btn-outline-primary" style={{ height: "35px", marginTop: "32px", fontSize: "12px", textAlign: "center", paddingTop: "8px", padding: "5px" }} onClick={onClickselectdetails}>
                                    Release
                                </button> */}
              </div>
              {/* <button>save gridlayout</button> */}
            </>
          ) : (
            ""
          )}
             <div
        className={"sliderBlock2 " + showAddForm4}
        style={{ overflow: "auto" }}
      >
        <div className="slide-r-title slider_title_style">
          <h6>Enter Details</h6>
          <span
            className="float-right closebtn"
            style={{ marginTop: "-30px", marginRight: "25px" }}
            onClick={onClickHideManualUpload2}
          >
            X
          </span>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <form>
              <div className="slide-body">
                <div className="row p-20p">
                  <div className="form-group col-xl-12 col-lg-12"></div>
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Work Order No<span className="err-txt">*</span>
                    </label>
                    <Select
                      closeMenuOnSelect={true}
                      value={manualUploadDetails1.work_order_no}
                      // multi={true}
                      // className={"border-radius-0"}
                      onChange={(e) => handlewrno(e)}
                      style={{ borderRadius: "0px" }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          fontSize: "0.8rem",
                          color: "#6e707e",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: "0.9rem",
                        }),
                      }}
                      options={wrlists}
                    />
                  </div>
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Truck No<span className="err-txt">*</span>
                    </label>
                    <CustomSelect
                      options={truckList}
                      value={manualUploadDetails1.truck_no}
                      onChange={(e) =>
                        setmanualUploadDetails1((prev) => ({
                          ...prev,
                          truck_no: e,
                        }))
                      }
                    />
                    {/* <Select
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails1.container_no}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) =>setmanualUploadDetails1(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                                singleValue:(provided,state)=>({
                                                    ...provided,
                                                    fontSize:'0.9rem'
                                                })
                                            }}
                                            options={containerList}
                                        /> */}
                  </div>
                  {newworkordercheck == true ? (
                    <>
                      <div className="form-group col-xl-12 col-lg-12">
                        <label className="mb-5p">
                          New Work Order No<span className="err-txt">*</span>
                        </label>
                        <Select
                          closeMenuOnSelect={true}
                          value={manualUploadDetails1.new_work_order_no}
                          // multi={true}
                          // className={"border-radius-0"}
                          onChange={(e) => handlewrno1(e)}
                          style={{ borderRadius: "0px" }}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              fontSize: "0.8rem",
                              color: "#6e707e",
                            }),
                            singleValue: (provided, state) => ({
                              ...provided,
                              fontSize: "0.9rem",
                            }),
                          }}
                          options={wrlists1}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {reassignbtnenable == false ? (
                    <div
                      onClick={onsubmitdata3}
                      className="form-group col-sm-12 mb-20p text-center"
                    >
                      <button type="submit" className="btn btn-success">
                        Assign
                      </button>
                    </div>
                  ) : checkforsubmit == false ? (
                    <>
                      {" "}
                      <div
                        onClick={onsubmitdata3}
                        className="form-group col-sm-12 mb-30p text-center"
                      >
                        <button type="submit" className="btn btn-success">
                          Re-Assign
                        </button>
                      </div>
                      <div
                        onClick={onsubmitdata2}
                        className="form-group col-sm-12 mb-30p text-center"
                      >
                        <button type="submit" className="btn btn-success">
                          Release
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {checkforsubmit == true ? (
                    <div
                      onClick={onClickreassignworkorder}
                      className="form-group col-sm-12 mb-20p text-center"
                    >
                      <button type="submit" className="btn btn-success">
                        Submit
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
            <div className={"sliderBlock2 " + showAddForm3} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Download DO Copies</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload3} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center">

                       <div className="col-xl-12 col-lg-12 form-group mt-15p">
                            <button onClick={() => downloadB2(files1.file1)} className="btn btn-warning1">
                                {files1.file1 && files1.file1.includes('_') ? files1.file1.split('_').slice(0, -1).join('_') : files1.file1}

                                <i
                                    style={{ color: downloadBtn, cursor: cursorPoint, marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}
                                    className="fa fa-download f20"
                                ></i>
                            </button>
                        </div>
                        <div className="col-xl-12 col-lg-12 form-group">
                            <div>
                                {
                                    files1.file2.map((file, index) => {
                                        return (
                                            <div className="mb-15p" key={index}>
                                                <button onClick={() => downloadB2(file)} className="btn btn-warning1">
                                                    {(file !== undefined && file !== "undefined")
                                                        ? (file.includes('_')
                                                            ? file.split('_').slice(0, -1).join('_')
                                                            : file)
                                                        : `Download File ${index + 2}`
                                                    }
                                                    <i
                                                        style={{ color: downloadBtn, cursor: cursorPoint, marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}
                                                        className="fa fa-download f20"
                                                    ></i>
                                                </button>
                                                <br />
                                            </div>
                                        );
                                    }) || ""
                                }
                            
                        
                        </div>
                        </div>
                    
                  
                    </div>
                </div>


            </div>
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>



    )
}
function removeDuplicates(arr, prop) {
    const seen = new Set();
    return arr.filter(obj => {
        const propValue = obj[prop];
        const propString = typeof propValue === 'object' ? JSON.stringify(propValue) : propValue;
        if (seen.has(propString)) {
            return false;
        }
        seen.add(propString);
        return true;
    });
}
function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
        if (arr.length > 0) {
            const comparison = (a, b) => {
                const dateA = new Date(a.modified_date);
                const dateB = new Date(b.modified_date);
                if (descending) {
                    return dateB - dateA; // Ascending order
                } else {
                    return dateA - dateB; // Descending order
                }
            };
            arr.sort(comparison);
            return arr;
        }
    } else {
        return arr;
    }
}
function dateComparator1(date1, date2) {
    if (!date1 || !date2) return 0;
    const date1Number = monthToComparableNumber1(date1);
    const date2Number = monthToComparableNumber1(date2);
    if (date1Number === null && date2Number === null) {
        return 0;  // Both dates are invalid, return equal
    }
    if (date1Number === null) {
        return -1;  // If date1 is invalid, consider it smaller
    }
    if (date2Number === null) {
        return 1;  // If date2 is invalid, consider it larger
    }
    return date1Number - date2Number;
}
// Helper function to convert date (DD-MM-YYYY) into a comparable numeric value
function monthToComparableNumber1(date) {
    if (!date || date.length !== 10) {
        return null;  // Invalid date format
    }
    // Extract year, month, and day from the string
    const day = parseInt(date.substring(0, 2), 10);
    const month = parseInt(date.substring(3, 5), 10);
    const year = parseInt(date.substring(6, 10), 10);
    // Return a numeric value in the format YYYYMMDD for comparison
    return year * 10000 + month * 100 + day;
}
function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
export default Updatetrucksdetails